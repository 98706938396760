* {
  box-sizing: border-box;
}
body {
  font-size: 1rem;
  text-decoration: none;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 300;
  letter-spacing: 0rem;
  line-height: 1.5rem;
  text-transform: none;
  color: #2f2f38;
}
body.mobile-menu-open {
  overflow: hidden;
}
html,
body,
.marketing-layout,
.app-layout {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100%;
  background: #fcf8f7;
  margin: 0;
}
.marketing-layout {
  background: #fff5f5;
}
.app-layout {
  padding: 0;
}
@media (max-width: 768px) {
  .app-layout {
    padding: 0 16px;
  }
}
.app-layout--content {
  align-self: center;
  width: 100%;
  max-width: 544px;
  padding: 48px 32px;
  display: flex;
  flex-direction: column;
  flex: 1px;
}
@media (max-width: 768px) {
  .app-layout--content {
    max-width: 100%;
    padding: 12px 0;
  }
}
.app-layout--content[data-fill] {
  max-width: 100%;
}
.app-layout--content[data-wide] {
  max-width: 664px;
}
.marketing-layout--content {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
a {
  text-decoration: none;
  color: #004742;
}
a:hover {
  color: #004742;
}
a.underline {
  text-decoration: underline;
}
button {
  color: #131215;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}
@media (max-width: 768px) {
  .hide-mobile {
    display: none !important;
  }
}
@media not all and (max-width: 768px) {
  .hide-desktop {
    display: none !important;
  }
}
.body-spacious-strong-xlarge {
  font-size: 1.25rem;
  text-decoration: none;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 400;
  letter-spacing: 0rem;
  line-height: 2rem;
  text-transform: none;
}
@media (max-width: 768px) {
  .s-body-spacious-strong-xlarge {
    font-size: 1.25rem;
    text-decoration: none;
    font-family: Chiswick Sans Text, sans-serif;
    font-weight: 400;
    letter-spacing: 0rem;
    line-height: 2rem;
    text-transform: none;
  }
}
.body-spacious-strong-large {
  font-size: 1.125rem;
  text-decoration: none;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 400;
  letter-spacing: 0rem;
  line-height: 1.875rem;
  text-transform: none;
}
@media (max-width: 768px) {
  .s-body-spacious-strong-large {
    font-size: 1.125rem;
    text-decoration: none;
    font-family: Chiswick Sans Text, sans-serif;
    font-weight: 400;
    letter-spacing: 0rem;
    line-height: 1.875rem;
    text-transform: none;
  }
}
.body-spacious-strong-medium {
  font-size: 1rem;
  text-decoration: none;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 400;
  letter-spacing: 0rem;
  line-height: 1.75rem;
  text-transform: none;
}
@media (max-width: 768px) {
  .s-body-spacious-strong-medium {
    font-size: 1rem;
    text-decoration: none;
    font-family: Chiswick Sans Text, sans-serif;
    font-weight: 400;
    letter-spacing: 0rem;
    line-height: 1.75rem;
    text-transform: none;
  }
}
.body-spacious-strong-small {
  font-size: 0.8125rem;
  text-decoration: none;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 400;
  letter-spacing: 0rem;
  line-height: 1.5rem;
  text-transform: none;
}
@media (max-width: 768px) {
  .s-body-spacious-strong-small {
    font-size: 0.8125rem;
    text-decoration: none;
    font-family: Chiswick Sans Text, sans-serif;
    font-weight: 400;
    letter-spacing: 0rem;
    line-height: 1.5rem;
    text-transform: none;
  }
}
.body-spacious-xlarge {
  font-size: 1.25rem;
  text-decoration: none;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 300;
  letter-spacing: 0rem;
  line-height: 2rem;
  text-transform: none;
}
@media (max-width: 768px) {
  .s-body-spacious-xlarge {
    font-size: 1.25rem;
    text-decoration: none;
    font-family: Chiswick Sans Text, sans-serif;
    font-weight: 300;
    letter-spacing: 0rem;
    line-height: 2rem;
    text-transform: none;
  }
}
.body-spacious-large {
  font-size: 1.125rem;
  text-decoration: none;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 300;
  letter-spacing: 0rem;
  line-height: 1.875rem;
  text-transform: none;
}
@media (max-width: 768px) {
  .s-body-spacious-large {
    font-size: 1.125rem;
    text-decoration: none;
    font-family: Chiswick Sans Text, sans-serif;
    font-weight: 300;
    letter-spacing: 0rem;
    line-height: 1.875rem;
    text-transform: none;
  }
}
.body-spacious-medium {
  font-size: 1rem;
  text-decoration: none;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 300;
  letter-spacing: 0rem;
  line-height: 1.75rem;
  text-transform: none;
}
@media (max-width: 768px) {
  .s-body-spacious-medium {
    font-size: 1rem;
    text-decoration: none;
    font-family: Chiswick Sans Text, sans-serif;
    font-weight: 300;
    letter-spacing: 0rem;
    line-height: 1.75rem;
    text-transform: none;
  }
}
.body-spacious-small {
  font-size: 0.8125rem;
  text-decoration: none;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 300;
  letter-spacing: 0.008125rem;
  line-height: 1.5rem;
  text-transform: none;
}
@media (max-width: 768px) {
  .s-body-spacious-small {
    font-size: 0.8125rem;
    text-decoration: none;
    font-family: Chiswick Sans Text, sans-serif;
    font-weight: 300;
    letter-spacing: 0.008125rem;
    line-height: 1.5rem;
    text-transform: none;
  }
}
.body-strong-xlarge {
  font-size: 1.25rem;
  text-decoration: none;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 400;
  letter-spacing: 0rem;
  line-height: 1.75rem;
  text-transform: none;
}
@media (max-width: 768px) {
  .s-body-strong-xlarge {
    font-size: 1.25rem;
    text-decoration: none;
    font-family: Chiswick Sans Text, sans-serif;
    font-weight: 400;
    letter-spacing: 0rem;
    line-height: 1.75rem;
    text-transform: none;
  }
}
.body-strong-large {
  font-size: 1.125rem;
  text-decoration: none;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 400;
  letter-spacing: 0rem;
  line-height: 1.625rem;
  text-transform: none;
}
@media (max-width: 768px) {
  .s-body-strong-large {
    font-size: 1.125rem;
    text-decoration: none;
    font-family: Chiswick Sans Text, sans-serif;
    font-weight: 400;
    letter-spacing: 0rem;
    line-height: 1.625rem;
    text-transform: none;
  }
}
.body-strong-medium {
  font-size: 1rem;
  text-decoration: none;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 400;
  letter-spacing: 0rem;
  line-height: 1.5rem;
  text-transform: none;
}
@media (max-width: 768px) {
  .s-body-strong-medium {
    font-size: 1rem;
    text-decoration: none;
    font-family: Chiswick Sans Text, sans-serif;
    font-weight: 400;
    letter-spacing: 0rem;
    line-height: 1.5rem;
    text-transform: none;
  }
}
.body-strong-small {
  font-size: 0.9375rem;
  text-decoration: none;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 400;
  letter-spacing: 0rem;
  line-height: 1.375rem;
  text-transform: none;
}
@media (max-width: 768px) {
  .s-body-strong-small {
    font-size: 0.9375rem;
    text-decoration: none;
    font-family: Chiswick Sans Text, sans-serif;
    font-weight: 400;
    letter-spacing: 0rem;
    line-height: 1.375rem;
    text-transform: none;
  }
}
.body-strong-xsmall {
  font-size: 0.8125rem;
  text-decoration: none;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 400;
  letter-spacing: 0rem;
  line-height: 1.25rem;
  text-transform: none;
}
@media (max-width: 768px) {
  .s-body-strong-xsmall {
    font-size: 0.8125rem;
    text-decoration: none;
    font-family: Chiswick Sans Text, sans-serif;
    font-weight: 400;
    letter-spacing: 0rem;
    line-height: 1.25rem;
    text-transform: none;
  }
}
.body-xlarge {
  font-size: 1.25rem;
  text-decoration: none;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 300;
  letter-spacing: 0rem;
  line-height: 1.75rem;
  text-transform: none;
}
@media (max-width: 768px) {
  .s-body-xlarge {
    font-size: 1.25rem;
    text-decoration: none;
    font-family: Chiswick Sans Text, sans-serif;
    font-weight: 300;
    letter-spacing: 0rem;
    line-height: 1.75rem;
    text-transform: none;
  }
}
.body-large {
  font-size: 1.125rem;
  text-decoration: none;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 300;
  letter-spacing: 0rem;
  line-height: 1.625rem;
  text-transform: none;
}
@media (max-width: 768px) {
  .s-body-large {
    font-size: 1.125rem;
    text-decoration: none;
    font-family: Chiswick Sans Text, sans-serif;
    font-weight: 300;
    letter-spacing: 0rem;
    line-height: 1.625rem;
    text-transform: none;
  }
}
.body-medium {
  font-size: 1rem;
  text-decoration: none;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 300;
  letter-spacing: 0rem;
  line-height: 1.5rem;
  text-transform: none;
}
@media (max-width: 768px) {
  .s-body-medium {
    font-size: 1rem;
    text-decoration: none;
    font-family: Chiswick Sans Text, sans-serif;
    font-weight: 300;
    letter-spacing: 0rem;
    line-height: 1.5rem;
    text-transform: none;
  }
}
.body-small {
  font-size: 0.9375rem;
  text-decoration: none;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 300;
  letter-spacing: 0rem;
  line-height: 1.375rem;
  text-transform: none;
}
@media (max-width: 768px) {
  .s-body-small {
    font-size: 0.9375rem;
    text-decoration: none;
    font-family: Chiswick Sans Text, sans-serif;
    font-weight: 300;
    letter-spacing: 0rem;
    line-height: 1.375rem;
    text-transform: none;
  }
}
.body-xsmall {
  font-size: 0.8125rem;
  text-decoration: none;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 300;
  letter-spacing: 0.008125rem;
  line-height: 1.25rem;
  text-transform: none;
}
@media (max-width: 768px) {
  .s-body-xsmall {
    font-size: 0.8125rem;
    text-decoration: none;
    font-family: Chiswick Sans Text, sans-serif;
    font-weight: 300;
    letter-spacing: 0.008125rem;
    line-height: 1.25rem;
    text-transform: none;
  }
}
.caps-strong-xsmall {
  font-size: 0.75rem;
  text-decoration: none;
  font-family: Chiswick Grotesque, sans-serif;
  font-weight: 600;
  letter-spacing: 0.105rem;
  line-height: 1.5rem;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .s-caps-strong-xsmall {
    font-size: 0.75rem;
    text-decoration: none;
    font-family: Chiswick Grotesque, sans-serif;
    font-weight: 600;
    letter-spacing: 0.105rem;
    line-height: 1.5rem;
    text-transform: uppercase;
  }
}
.caps-strong-medium {
  font-size: 0.9375rem;
  text-decoration: none;
  font-family: Chiswick Grotesque, sans-serif;
  font-weight: 600;
  letter-spacing: 0.1125rem;
  line-height: 1.5rem;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .s-caps-strong-medium {
    font-size: 0.9375rem;
    text-decoration: none;
    font-family: Chiswick Grotesque, sans-serif;
    font-weight: 600;
    letter-spacing: 0.1125rem;
    line-height: 1.5rem;
    text-transform: uppercase;
  }
}
.caps-strong-small {
  font-size: 0.8125rem;
  text-decoration: none;
  font-family: Chiswick Grotesque, sans-serif;
  font-weight: 600;
  letter-spacing: 0.11375rem;
  line-height: 1.5rem;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .s-caps-strong-small {
    font-size: 0.8125rem;
    text-decoration: none;
    font-family: Chiswick Grotesque, sans-serif;
    font-weight: 600;
    letter-spacing: 0.11375rem;
    line-height: 1.5rem;
    text-transform: uppercase;
  }
}
.caps-xsmall {
  font-size: 0.75rem;
  text-decoration: none;
  font-family: Chiswick Grotesque, sans-serif;
  font-weight: 400;
  letter-spacing: 0.105rem;
  line-height: 1.5rem;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .s-caps-xsmall {
    font-size: 0.75rem;
    text-decoration: none;
    font-family: Chiswick Grotesque, sans-serif;
    font-weight: 400;
    letter-spacing: 0.105rem;
    line-height: 1.5rem;
    text-transform: uppercase;
  }
}
.caps-medium {
  font-size: 0.9375rem;
  text-decoration: none;
  font-family: Chiswick Grotesque, sans-serif;
  font-weight: 400;
  letter-spacing: 0.1125rem;
  line-height: 1.5rem;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .s-caps-medium {
    font-size: 0.9375rem;
    text-decoration: none;
    font-family: Chiswick Grotesque, sans-serif;
    font-weight: 400;
    letter-spacing: 0.1125rem;
    line-height: 1.5rem;
    text-transform: uppercase;
  }
}
.caps-small {
  font-size: 0.8125rem;
  text-decoration: none;
  font-family: Chiswick Grotesque, sans-serif;
  font-weight: 400;
  letter-spacing: 0.11375rem;
  line-height: 1.5rem;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .s-caps-small {
    font-size: 0.8125rem;
    text-decoration: none;
    font-family: Chiswick Grotesque, sans-serif;
    font-weight: 400;
    letter-spacing: 0.11375rem;
    line-height: 1.5rem;
    text-transform: uppercase;
  }
}
.headings-900 {
  font-size: 3.25rem;
  text-decoration: none;
  font-family: Holen Vintage, serif;
  font-weight: 400;
  letter-spacing: 0.0325rem;
  line-height: 3.75rem;
  text-transform: none;
}
@media (max-width: 768px) {
  .s-headings-900 {
    font-size: 3.25rem;
    text-decoration: none;
    font-family: Holen Vintage, serif;
    font-weight: 400;
    letter-spacing: 0.0325rem;
    line-height: 3.75rem;
    text-transform: none;
  }
}
.headings-850 {
  font-size: 2.75rem;
  text-decoration: none;
  font-family: Holen Vintage, serif;
  font-weight: 400;
  letter-spacing: 0.0275rem;
  line-height: 3.5rem;
  text-transform: none;
}
@media (max-width: 768px) {
  .s-headings-850 {
    font-size: 2.75rem;
    text-decoration: none;
    font-family: Holen Vintage, serif;
    font-weight: 400;
    letter-spacing: 0.0275rem;
    line-height: 3.5rem;
    text-transform: none;
  }
}
.headings-800 {
  font-size: 2.5rem;
  text-decoration: none;
  font-family: Holen Vintage, serif;
  font-weight: 400;
  letter-spacing: 0rem;
  line-height: 3.25rem;
  text-transform: none;
}
@media (max-width: 768px) {
  .s-headings-800 {
    font-size: 2.5rem;
    text-decoration: none;
    font-family: Holen Vintage, serif;
    font-weight: 400;
    letter-spacing: 0rem;
    line-height: 3.25rem;
    text-transform: none;
  }
}
.headings-750 {
  font-size: 2.25rem;
  text-decoration: none;
  font-family: Holen Vintage, serif;
  font-weight: 400;
  letter-spacing: 0.045rem;
  line-height: 2.75rem;
  text-transform: none;
}
@media (max-width: 768px) {
  .s-headings-750 {
    font-size: 2.25rem;
    text-decoration: none;
    font-family: Holen Vintage, serif;
    font-weight: 400;
    letter-spacing: 0.045rem;
    line-height: 2.75rem;
    text-transform: none;
  }
}
.headings-700 {
  font-size: 2rem;
  text-decoration: none;
  font-family: Holen Vintage, serif;
  font-weight: 400;
  letter-spacing: 0.04rem;
  line-height: 2.5rem;
  text-transform: none;
}
@media (max-width: 768px) {
  .s-headings-700 {
    font-size: 2rem;
    text-decoration: none;
    font-family: Holen Vintage, serif;
    font-weight: 400;
    letter-spacing: 0.04rem;
    line-height: 2.5rem;
    text-transform: none;
  }
}
.headings-650 {
  font-size: 1.75rem;
  text-decoration: none;
  font-family: Holen Vintage, serif;
  font-weight: 400;
  letter-spacing: 0.035rem;
  line-height: 2.25rem;
  text-transform: none;
}
@media (max-width: 768px) {
  .s-headings-650 {
    font-size: 1.75rem;
    text-decoration: none;
    font-family: Holen Vintage, serif;
    font-weight: 400;
    letter-spacing: 0.035rem;
    line-height: 2.25rem;
    text-transform: none;
  }
}
.headings-600 {
  font-size: 1.5rem;
  text-decoration: none;
  font-family: Holen Vintage, serif;
  font-weight: 400;
  letter-spacing: 0.015rem;
  line-height: 2rem;
  text-transform: none;
}
@media (max-width: 768px) {
  .s-headings-600 {
    font-size: 1.5rem;
    text-decoration: none;
    font-family: Holen Vintage, serif;
    font-weight: 400;
    letter-spacing: 0.015rem;
    line-height: 2rem;
    text-transform: none;
  }
}
.headings-500 {
  font-size: 1.25rem;
  text-decoration: none;
  font-family: Holen Vintage, serif;
  font-weight: 400;
  letter-spacing: 0.0125rem;
  line-height: 1.75rem;
  text-transform: none;
}
@media (max-width: 768px) {
  .s-headings-500 {
    font-size: 1.25rem;
    text-decoration: none;
    font-family: Holen Vintage, serif;
    font-weight: 400;
    letter-spacing: 0.0125rem;
    line-height: 1.75rem;
    text-transform: none;
  }
}
.headings-400 {
  font-size: 1.125rem;
  text-decoration: none;
  font-family: Holen Vintage, serif;
  font-weight: 400;
  letter-spacing: 0.01125rem;
  line-height: 1.625rem;
  text-transform: none;
}
@media (max-width: 768px) {
  .s-headings-400 {
    font-size: 1.125rem;
    text-decoration: none;
    font-family: Holen Vintage, serif;
    font-weight: 400;
    letter-spacing: 0.01125rem;
    line-height: 1.625rem;
    text-transform: none;
  }
}
.headings-300 {
  font-size: 1rem;
  text-decoration: none;
  font-family: Holen Vintage, serif;
  font-weight: 400;
  letter-spacing: 0.01rem;
  line-height: 1.5rem;
  text-transform: none;
}
@media (max-width: 768px) {
  .s-headings-300 {
    font-size: 1rem;
    text-decoration: none;
    font-family: Holen Vintage, serif;
    font-weight: 400;
    letter-spacing: 0.01rem;
    line-height: 1.5rem;
    text-transform: none;
  }
}
.ta-center {
  text-align: center;
}
.underlink a {
  color: inherit;
  text-decoration: underline;
}
.peach-gray-200 {
  color: #edd0cc;
}
.peach-gray-100 {
  color: #f2eae9;
}
.peach-700 {
  color: #e1776b;
}
.peach-500 {
  color: #f0877b;
}
.peach-300 {
  color: #f8c7bc;
}
.peach-200 {
  color: #fadbd2;
}
.peach-100 {
  color: #ffe9e5;
}
.peach-50 {
  color: #fff5f5;
}
.peach-25 {
  color: #fcf8f7;
}
.green-gray-500 {
  color: #99ada5;
}
.green-gray-400 {
  color: #aabdb4;
}
.green-gray-300 {
  color: #bcccc5;
}
.green-gray-200 {
  color: #dae8e4;
}
.green-gray-100 {
  color: #edf5f3;
}
.green-gray-50 {
  color: #f0faf7;
}
.orange-900 {
  color: #4d2e00;
}
.orange-500 {
  color: #ff9900;
}
.orange-300 {
  color: #ffebcc;
}
.orange-100 {
  color: #fff5e7;
}
.red-900 {
  color: #5c0011;
}
.red-700 {
  color: #8e1027;
}
.red-500 {
  color: #c91737;
}
.red-300 {
  color: #f7d7dd;
}
.red-100 {
  color: #ffeaeb;
}
.green-900 {
  color: #082e2b;
}
.green-800 {
  color: #003a36;
}
.green-700 {
  color: #004742;
}
.green-500 {
  color: #005a54;
}
.green-400 {
  color: #a6e3df;
}
.green-300 {
  color: #cef2f0;
}
.green-200 {
  color: #e8fdfb;
}
.gray-900 {
  color: #131215;
}
.gray-850 {
  color: #27272d;
}
.gray-800 {
  color: #2f2f38;
}
.gray-750 {
  color: #383945;
}
.gray-700 {
  color: #50505e;
}
.gray-600 {
  color: #7a7986;
}
.gray-500 {
  color: #a9a9af;
}
.gray-400 {
  color: #bbbbbf;
}
.gray-300 {
  color: #cfcfd1;
}
.gray-200 {
  color: #dfdfe1;
}
.gray-100 {
  color: #e9e9ea;
}
.gray-50 {
  color: #f4f4f5;
}
.gray-25 {
  color: #f9f9fa;
}
.shades-white {
  color: #ffffff;
}
.shades-black {
  color: #000000;
}
.spinner {
  height: 32px;
  width: 32px;
  flex: none;
  transition: opacity 0.5s;
}
.spinner[data-size=small] {
  height: 16px;
  width: 16px;
}
.spinner[data-size=medium] {
  height: 24px;
  width: 24px;
}
.spinner[data-fill=true] {
  height: 100%;
  width: 100%;
  flex: 1 1 100%;
}
.spinner > div {
  height: 100%;
  width: 100%;
}
.spinner > div > div {
  margin: 0 auto;
  height: 100%;
  width: 100%;
  border: 3px solid rgba(0, 0, 0, 0.5);
  border-top-color: rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  animation: spinner__rotation 0.8s ease infinite;
}
@keyframes spinner__rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.button {
  font-size: 0.8125rem;
  text-decoration: none;
  font-family: Chiswick Grotesque, sans-serif;
  font-weight: 400;
  letter-spacing: 0.11375rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  width: fit-content;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 999px;
  position: relative;
}
.button:hover {
  cursor: pointer;
  text-decoration: none;
}
.button:focus,
.button:active {
  border: 2px solid #edd0cc;
}
.button[data-variant=primary] {
  background: #f0877b;
  border: 1px solid #f0877b;
  color: #ffffff;
}
.button[data-variant=primary]:hover {
  background: #e1776b;
  border: 1px solid #e1776b;
}
.button[data-variant=secondary] {
  background: none;
  color: #004742;
  border: 1px solid #003a36;
}
.button[data-variant=secondary]:hover {
  color: #003a36;
}
.button[data-variant=secondary-white] {
  background: transparent;
  color: #ffffff;
  border: 2px solid #ffffff;
}
.button[data-variant=secondary-white]:hover {
  color: #ffffff;
  cursor: pointer;
}
.button[data-variant=tertiary] {
  background: transparent;
  color: #004742;
  border: 2px solid transparent;
}
.button[data-variant=tertiary]:hover {
  color: #004742;
  background: #f4f4f5;
  cursor: pointer;
}
.button[data-variant=tertiary][data-black=true] {
  color: #000000;
}
.button[data-variant=danger] {
  background: transparent;
  color: #c91737;
  border: 2px solid #c91737;
}
.button[data-variant=danger]:hover {
  border-color: #8e1027;
  color: #8e1027;
  cursor: pointer;
}
.button[data-variant=danger]:focus-visible,
.button[data-variant=danger]:active {
  outline: 0;
  border: 3px solid #c91737;
}
.button[data-size=large] {
  height: 56px;
  padding: 20px 32px;
}
.button[data-size=large][data-icon=true] {
  padding: 16px;
  width: 54px;
}
.button[data-size=medium] {
  height: 46px;
  padding: 12px 24px;
}
.button[data-size=medium][data-icon=true] {
  padding: 12px;
  width: 46px;
}
.button[data-size=small] {
  height: 38px;
  padding: 8px 12px;
}
.button[data-size=small][data-icon=true] {
  padding: 7px;
  width: 38px;
}
.button svg {
  width: 20px;
  height: 20px;
}
.button svg:first-child {
  margin-right: 4px;
}
.button svg:last-child {
  margin-left: 4px;
}
.button[data-icon=true] svg {
  margin: 0;
}
.button[data-fill=true] {
  display: flex;
  width: 100%;
}
@media (max-width: 768px) {
  .button[data-fill=mobile] {
    width: 100%;
  }
}
.button .spinner {
  position: absolute;
  right: 16px;
  display: none;
}
.button[data-submitting=true] .spinner {
  display: flex;
}
.content-header {
  padding: 10px 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  background: #FFFDFC;
  box-shadow: 0px 2px 2px 0px #00000005;
  outline: 0;
}
@media (max-width: 768px) {
  .content-header {
    height: 52px;
    background: none;
    padding: 0;
    box-shadow: none;
  }
}
.content-header--left-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 18px;
}
.content-header--left-logo a {
  height: 18px;
}
.content-header--left-logo svg {
  height: 18px;
  width: auto;
}
.content-header--right-icon {
  display: flex;
  align-items: center;
}
.content-header--right-icon svg {
  height: 24px;
  width: 24px;
  color: #000000;
}
.content-header--icon {
  height: 24px;
  width: 24px;
}
.content-header--icon svg {
  height: 24px;
  width: 24px;
  color: #004742;
}
.content-header--icon router-link,
.content-header--icon a {
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .content-header--logo {
    display: none;
  }
}
.content-header--logo svg {
  height: 20px;
  width: auto;
  color: #004742;
}
.garden-card {
  display: block;
  border-radius: 4px;
  border: 1px solid #e9e9ea;
  background: #ffffff;
  overflow: hidden;
  box-shadow: 0px 1px 2px 0px #0000000a;
  outline: 0;
}
@media (max-width: 768px) {
  .garden-card {
    max-width: 100%;
  }
}
.garden-card router-link,
.garden-card a {
  display: block;
  height: fit-content;
}
.garden-card picture {
  display: block;
  height: fit-content;
}
.garden-card img {
  display: block;
  width: 100%;
  height: auto;
  background: #ffffff;
  background-size: 100% 100%;
  object-fit: cover;
  aspect-ratio: 300 / 150;
}
.garden-card h2 {
  margin: 0 0 0 16px;
  color: #004742;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.garden-card--details {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px;
  text-transform: uppercase;
  flex: 1 1 auto;
  white-space: nowrap;
}
.garden-card--details svg {
  height: 16px;
  width: 16px;
  margin-left: 5px;
}
.floating-action-button {
  margin: 16px 0;
}
@media (max-width: 768px) {
  .floating-action-button {
    height: 56px;
  }
}
.floating-action-button > div {
  padding-top: 16px;
}
@media (max-width: 768px) {
  .floating-action-button > div {
    position: fixed;
    bottom: 16px;
    left: 16px;
    right: 16px;
    width: calc(100% - 32px);
    display: flex;
    justify-content: flex-end;
    pointer-events: none;
  }
  .floating-action-button > div .button {
    box-shadow: 0px 4px 16px 1px #00000014;
    outline: 0;
  }
}
.floating-action-button > div .full-width {
  width: 100%;
}
.floating-action-button[data-desktop] > div {
  position: fixed;
  bottom: 16px;
  left: 16px;
  right: 16px;
  width: calc(100% - 32px);
  display: flex;
  justify-content: flex-end;
  pointer-events: none;
}
.floating-action-button[data-desktop] > div .button {
  box-shadow: 0px 6px 32px 0px #00000029;
  outline: 0;
}
.floating-action-button router-link {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.floating-action-button button,
.floating-action-button a {
  pointer-events: initial;
}
:is(.floating-action-button button, .floating-action-button a) .floating-action-button--secondary-text {
  color: #a6e3df;
  font-weight: lighter;
}
.field-error {
  display: flex;
  align-items: flex-start;
  color: #c91737;
  padding-top: 8px;
}
.field-error svg {
  flex: 0 0 20px;
  height: 20px;
  width: 20px;
  margin-right: 6px;
}
.text-input {
  width: 375px;
  max-width: 100%;
}
.text-input[data-fill=true] {
  width: 100%;
}
@media (max-width: 768px) {
  .text-input[data-fill=mobile] {
    width: 100%;
  }
}
.text-input--container {
  position: relative;
  height: 60px;
}
.text-input--label {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 16px 14px 16px;
  color: #7a7986;
  pointer-events: none;
  transition: all 0.15s;
}
.text-input:focus-within .text-input--label,
.text-input[data-filled=true] .text-input--label {
  font-size: 0.8125rem;
  text-decoration: none;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 300;
  letter-spacing: 0.008125rem;
  line-height: 1.25rem;
  text-transform: none;
  padding-top: 20px;
  height: 8px;
}
.text-input[data-disabled=true] .text-input--label {
  color: #cfcfd1;
}
.text-input[data-error=true] .text-input--label {
  color: #c91737;
}
.text-input--input {
  display: block;
  width: 100%;
  height: 100%;
  box-shadow: 0px 1px 2px 0px #0000000a;
  outline: 0;
  border-radius: 4px;
  border: 1px solid #f2eae9;
  padding: 16px 16px 14px;
  margin: 0;
  color: #131215;
  background: #ffffff;
}
.text-input--input::placeholder {
  color: #bbbbbf;
}
.text-input--input:not(:focus)::placeholder {
  color: transparent;
}
.text-input--input:focus,
.text-input[data-filled=true] .text-input--input {
  padding-top: 22px;
  padding-bottom: 4px;
}
.text-input--input:focus {
  border: 2px solid #edd0cc;
  margin-left: -1px;
}
.text-input[data-disabled=true][data-filled=true] .text-input--input {
  color: #cfcfd1;
  -webkit-text-fill-color: #cfcfd1;
}
.text-input[data-error=true] .text-input--input {
  border: 1px solid #c91737;
  color: #c91737;
}
.text-input[data-error=true] .text-input--input:focus-visible {
  border: 2px solid #c91737;
}
.garden-form {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.garden-form h1 {
  color: #004742;
}
.garden-form checkbox-input a {
  text-decoration: underline;
}
.garden-form--type-row {
  display: flex;
  column-gap: 16px;
}
.garden-form--type-row select-input:first-child {
  flex: 1;
}
.show-garden-page {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.show-garden-page h1 {
  color: #004742;
}
.show-garden-page--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 768px) {
  .show-garden-page--header {
    justify-content: center;
    text-align: center;
  }
}
.show-garden-page--details {
  color: #2f2f38;
}
@media (max-width: 768px) {
  .show-garden-page--details {
    text-align: center;
  }
}
.show-garden-page--search {
  margin-top: 16px;
}
.show-garden-page--empty {
  flex-grow: 1;
  display: flex;
  margin: 16px 0;
  padding: 16px;
  flex-direction: column;
  border-radius: 4px;
  border: 1px dashed #edd0cc;
  justify-content: center;
  align-items: center;
  max-height: 600px;
}
@media (max-width: 768px) {
  .show-garden-page--empty {
    max-height: unset;
  }
}
.show-garden-page--empty h2 {
  color: #004742;
}
.show-garden-page--empty p {
  color: #50505e;
  justify-content: center;
  text-align: center;
}
.show-garden-page--list {
  border-radius: 4px;
  border: 1px solid #F4ECE9;
  background: #ffffff;
  padding: 12px;
  margin: 16px 0;
  box-shadow: 0px 1px 2px 0px #0000000a;
  outline: 0;
}
.show-garden-page--list > a {
  display: flex;
  align-items: center;
  column-gap: 16px;
  padding: 14px 0;
  border-bottom: 1px solid #f2eae9;
}
.show-garden-page--list > a:first-child {
  padding-top: 0;
}
.show-garden-page--list > a:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.show-garden-page--list > a svg {
  padding: 16px;
  height: 66px;
  width: 88px;
  border-radius: 4px;
  border: 1px solid #edd0cc;
  color: #edd0cc;
}
.show-garden-page--list > a img {
  height: 66px;
  width: 88px;
  object-fit: cover;
  border-radius: 4px;
}
.show-garden-page--list > a div {
  display: flex;
  flex-direction: column;
}
.show-garden-page--list > a h2 {
  color: #004742;
}
.show-garden-page--list > a p {
  margin: 0;
  color: #50505e;
  font-style: italic;
}
.plant-form {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.plant-form h1 {
  margin-bottom: 4px;
  color: #004742;
}
.plant-form .floating-action-button {
  margin-top: 0;
}
show-plant-page h2 {
  margin-top: 32px;
  color: #131215;
}
show-plant-page dl {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  row-gap: 12px;
}
show-plant-page dt {
  flex-basis: 35%;
  overflow: hidden;
  white-space: nowrap;
}
show-plant-page dd {
  margin-left: 16px;
  flex-basis: calc(65% - 16px);
  color: #004742;
  font-style: italic;
}
show-plant-page ul {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
show-plant-page ul a {
  color: #005a54;
}
.show-plant-page--img {
  max-width: 100%;
  border-radius: 4px;
  margin-bottom: -8px;
  object-fit: cover;
  aspect-ratio: 4 / 3;
}
.show-plant-page--labels {
  display: flex;
  flex-direction: column;
  margin: 16px 0;
}
.show-plant-page--labels .show-plant-page--label-row,
.show-plant-page--labels .show-plant-page--label-row-active {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
}
:is(.show-plant-page--labels .show-plant-page--label-row, .show-plant-page--labels .show-plant-page--label-row-active) .show-plant-page--label-id {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
:is(.show-plant-page--labels .show-plant-page--label-row, .show-plant-page--labels .show-plant-page--label-row-active) .show-plant-page--label-id .show-plant-page--label-name {
  display: flex;
}
:is(.show-plant-page--labels .show-plant-page--label-row, .show-plant-page--labels .show-plant-page--label-row-active) .show-plant-page--label-id .show-plant-page--label-name span {
  font-size: 100px;
  margin: 0 4px 0 -6px;
}
:is(.show-plant-page--labels .show-plant-page--label-row, .show-plant-page--labels .show-plant-page--label-row-active) .show-plant-page--label-id .show-plant-page--label-name p {
  color: #004742;
  margin: 0;
}
.show-plant-page--labels .show-plant-page--label-row-active {
  background: #ffe9e5;
  padding: 4px 4px 4px 8px;
  border-radius: 4px;
  margin-left: -8px;
}
.show-plant-page--labels .show-plant-page--label-row-active .show-plant-page--label-tapped {
  background: #f0877b;
  color: #ffe9e5;
  border-radius: 3px;
  margin-left: 8px;
  padding: 0 4px;
  font-size: 13px;
}
.login-form {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.login-form p {
  margin: 0;
}
.tap-page {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.tap-page header {
  margin-bottom: 16px;
}
.tap-page p {
  margin: 0;
}
.tap-page--or {
  text-align: center;
}
.select-input {
  width: 375px;
  max-width: 100%;
  cursor: pointer;
}
.select-input[data-fill=true] {
  width: 100%;
}
.select-input--container {
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
  padding: 16px 28px 14px 16px;
  box-shadow: 0px 1px 2px 0px #0000000a;
  outline: 0;
  border: 1px solid #f2eae9;
  border-radius: 4px;
  background: #ffffff;
}
.select-input--container:focus-within {
  border: 2px solid #edd0cc;
  padding: 15px 27px 13px 15px;
}
.select-input--container svg {
  color: #004742;
  position: absolute;
  right: 20px;
  pointer-events: none;
  transition: transform 200ms;
}
.select-input--container select {
  color: #131215;
  -webkit-appearance: none;
  appearance: none;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 0;
  background: transparent;
  outline: 0;
  font-size: 1rem;
  text-decoration: none;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 300;
  letter-spacing: 0rem;
  line-height: 1.5rem;
  text-transform: none;
  cursor: pointer;
  padding-right: 20px;
}
.select-input--container select[data-unset=true] {
  color: #7a7986;
}
.select-input--container select:active + svg {
  transform: rotate(-180deg);
}
.select-input[data-error=true] .select-input--container {
  border: 1px solid #c91737;
}
.select-input[data-error=true] .select-input--container:focus-within {
  border: 2px solid #c91737;
}
.select-input[data-error=true] .select-input--container select {
  color: #c91737;
}
.select-input[data-error=true] .select-input--container svg {
  color: #c91737;
}
textarea-input {
  width: 375px;
  max-width: 100%;
}
textarea-input[data-fill=true] {
  width: 100%;
}
@media (max-width: 768px) {
  textarea-input[data-fill=mobile] {
    width: 100%;
  }
}
.textarea-input--container {
  position: relative;
  display: flex;
  flex-direction: column;
}
.textarea-input--container label {
  position: absolute;
  left: 2px;
  right: 15px;
  top: 2px;
  border-radius: 4px 0 0 4px;
  padding: 16px 16px 24px;
  color: #7a7986;
  pointer-events: none;
  transition: all 0.15s;
  background: #ffffff;
}
textarea-input:focus-within :is(.textarea-input--container label),
textarea-input[data-filled=true] :is(.textarea-input--container label) {
  font-size: 0.8125rem;
  text-decoration: none;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 300;
  letter-spacing: 0.008125rem;
  line-height: 1.25rem;
  text-transform: none;
  height: 8px;
}
textarea-input[data-error=true] :is(.textarea-input--container label) {
  color: #c91737;
}
textarea-input[data-disabled=true] :is(.textarea-input--container label) {
  color: #cfcfd1;
}
.textarea-input--container textarea {
  box-shadow: 0px 1px 2px 0px #0000000a;
  outline: 0;
  border: 1px solid #f2eae9;
  border-radius: 4px;
  background: #ffffff;
  color: #131215;
  resize: none;
  margin: 1px;
  padding: 36px 16px 14px;
  font-size: 1rem;
  text-decoration: none;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 300;
  letter-spacing: 0rem;
  line-height: 1.5rem;
  text-transform: none;
}
.textarea-input--container textarea::placeholder {
  color: #bbbbbf;
}
.textarea-input--container textarea:not(:focus)::placeholder {
  color: transparent;
}
.textarea-input--container textarea:focus {
  border: 2px solid #edd0cc;
  margin: 0;
}
textarea-input[data-error=true] :is(.textarea-input--container textarea) {
  border: 1px solid #c91737;
  color: #c91737;
}
textarea-input[data-error=true] :is(.textarea-input--container textarea):focus {
  border: 2px solid #c91737;
}
image-upload {
  position: relative;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 2px 0px #0000000a;
  outline: 0;
  border: 1px solid #f2eae9;
  border-radius: 4px;
  background: #ffffff;
}
image-upload input {
  display: none;
}
image-upload button {
  height: 40px;
  border-radius: 4px;
  border: 0;
  background: #ffffff;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: none;
  color: #000000;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 300;
  box-shadow: 0px 2px 8px 0px #0000000f;
  outline: 0;
}
image-upload button[data-change-button] {
  display: flex;
  align-items: center;
  padding: 0 12px;
  column-gap: 4px;
}
image-upload img {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  object-fit: cover;
}
image-upload[data-set=true] .image-upload--hint {
  display: none;
}
image-upload[data-set=true] canvas {
  visibility: visible;
  pointer-events: initial;
}
image-upload[data-set=true] button[data-remove-button] {
  display: flex;
  width: 40px;
}
.image-upload--hint {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #50505e;
  cursor: pointer;
}
.image-upload--buttons {
  position: absolute;
  display: flex;
  align-items: center;
  column-gap: 8px;
  right: 8px;
  bottom: 8px;
}
date-input {
  width: 375px;
  max-width: 100%;
}
date-input[data-fill=true] {
  width: 100%;
}
@media (max-width: 768px) {
  date-input[data-fill=mobile] {
    width: 100%;
  }
}
.date-input--container {
  position: relative;
  height: 60px;
}
.date-input--container label {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 16px 14px 16px;
  color: #7a7986;
  pointer-events: none;
  transition: all 0.15s;
}
date-input:focus-within :is(.date-input--container label),
date-input[data-filled=true] :is(.date-input--container label) {
  font-size: 0.8125rem;
  text-decoration: none;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 300;
  letter-spacing: 0.008125rem;
  line-height: 1.25rem;
  text-transform: none;
  padding-top: 20px;
  height: 8px;
}
date-input[data-disabled=true] :is(.date-input--container label) {
  color: #cfcfd1;
}
date-input[data-error=true] :is(.date-input--container label) {
  color: #c91737;
}
.date-input--container input {
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-appearance: none;
  appearance: none;
  cursor: text;
  box-shadow: 0px 1px 2px 0px #0000000a;
  outline: 0;
  border: 1px solid #f2eae9;
  border-radius: 4px;
  padding: 16px 16px 14px;
  margin: 0;
  color: #131215;
  background: #ffffff;
  font-size: 1rem;
  text-decoration: none;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 300;
  letter-spacing: 0rem;
  line-height: 1.5rem;
  text-transform: none;
}
.date-input--container input::placeholder {
  padding: 0;
  color: #bbbbbf;
}
date-input[data-filled=false] :is(.date-input--container input):not(:focus) {
  color: transparent;
}
.date-input--container input::-webkit-date-and-time-value {
  text-align: left;
}
.date-input--container input::-webkit-datetime-edit {
  padding: 0;
}
.date-input--container input::-webkit-datetime-edit-month-field {
  padding-left: 0;
}
date-input[data-filled=true] :is(.date-input--container input:not(:focus))::-webkit-datetime-edit {
  opacity: 1px;
}
date-input[data-filled=false] :is(.date-input--container input:not(:focus))::-webkit-datetime-edit {
  opacity: 0;
}
.date-input--container input:focus,
date-input[data-filled=true] :is(.date-input--container input) {
  padding-top: 22px;
  padding-bottom: 4px;
}
.date-input--container input:focus {
  border: 2px solid #edd0cc;
}
date-input[data-disabled=true][data-filled=true] :is(.date-input--container input) {
  color: #cfcfd1;
  -webkit-text-fill-color: #cfcfd1;
}
date-input[data-error=true] :is(.date-input--container input) {
  border: 1px solid #c91737;
  color: #c91737;
}
date-input[data-error=true] :is(.date-input--container input):focus-visible {
  border: 2px solid #c91737;
}
.date-input--container button {
  position: absolute;
  right: 24px;
  top: 22px;
  color: #005a54;
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  cursor: pointer;
}
date-input[data-filled=false] :is(.date-input--container button) {
  opacity: 0;
  pointer-events: none;
}
.choose-garden-page--subtitle {
  margin: 0 0 20px 0;
  color: #2f2f38;
}
.choose-garden-page--gardens {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
.choose-garden-page--card-title {
  color: #131215;
}
.choose-garden-page--card-subtitle {
  color: #50505e;
}
.radio {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  position: relative;
  height: 24px;
  width: 24px;
}
.radio::before {
  display: block;
  width: 100%;
  height: 100%;
  content: "";
  border-radius: 100%;
  border: 1px solid #cfcfd1;
}
.radio:hover::before,
.radio:checked::before {
  border-color: #004742;
}
.radio:checked::after {
  position: absolute;
  top: 5px;
  left: 5px;
  content: "";
  display: block;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  background: #004742;
}
.link-plant-page--subtitle {
  margin: 0 0 20px 0;
  color: #2f2f38;
}
.link-plant-page--plants {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px #0000000a;
  outline: 0;
}
.link-plant-page--plants radio-card {
  padding: 0 16px;
}
.link-plant-page--plants radio-card label {
  border-radius: 0;
  border: 0;
  box-shadow: none;
  border-bottom: 1px solid #f2eae9;
  padding: 12px 0;
}
.link-plant-page--plants radio-card:last-child {
  border-bottom: 0;
}
.link-plant-page--plants radio-card[data-checked=true] label {
  border-color: #f2eae9;
}
.link-plant-page--plants:has(radio-card[data-checked=true]) radio-card[data-checked=false] {
  opacity: 0.5;
}
.link-plant-page--plants:has(radio-card[data-checked=true]) radio-card[data-checked=false]:hover {
  opacity: 1;
}
.link-plant-page--card-image svg {
  background: #cef2f0;
  padding: 16px;
  height: 80px;
  width: 88px;
  border-radius: 4px;
}
.link-plant-page--card-image img {
  height: 66px;
  width: 88px;
  object-fit: cover;
  border-radius: 4px;
}
.link-plant-page--card-title {
  color: #131215;
}
.link-plant-page--card-subtitle {
  font-style: italic;
  color: #50505e;
}
radio-card label {
  box-shadow: 0px 1px 2px 0px #0000000a;
  outline: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;
  border-radius: 4px;
  background: #ffffff;
  padding: 20px;
  border: 1px solid #e9e9ea;
  cursor: pointer;
}
radio-card[data-checked=true] label {
  border-color: #004742;
}
.garden-index-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.garden-index-page--garden-list {
  align-self: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
@media (max-width: 768px) {
  .garden-index-page--garden-list {
    row-gap: 16px;
  }
}
.garden-index-page--empty {
  flex-grow: 1;
  display: flex;
  margin: 16px 0;
  padding: 16px;
  flex-direction: column;
  border-radius: 4px;
  border: 1px dashed #edd0cc;
  justify-content: center;
  align-items: center;
  max-height: 600px;
}
@media (max-width: 768px) {
  .garden-index-page--empty {
    max-height: unset;
  }
}
.garden-index-page--empty h2 {
  color: #004742;
}
.garden-index-page--empty p {
  color: #50505e;
  justify-content: center;
  text-align: center;
}
.home-page marketing-header:not([data-scrolled]) .marketing-header--basket-items {
  border: 2px solid #f8c7bc;
}
.home-page--content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #FCF8F7;
  margin-top: -72px;
}
.home-page--hero {
  padding: 0 64px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #131215;
  background: #FFCFC3;
  margin-bottom: 96px;
}
@media (max-width: 1024px) {
  .home-page--hero {
    align-items: flex-start;
    padding: 96px 16px 64px;
    height: auto;
  }
}
@media (max-width: 768px) {
  .home-page--hero {
    width: 100%;
    border-radius: 0;
    padding: 96px 16px 64px;
    height: auto;
  }
}
.home-page--hero-inner {
  display: flex;
  align-items: center;
  column-gap: 48px;
  max-width: 1320px;
}
@media (max-width: 1024px) {
  .home-page--hero-inner {
    flex-direction: column;
    max-width: 100%;
  }
}
.home-page--hero-left {
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  min-width: 375px;
}
@media (max-width: 1024px) {
  .home-page--hero-left {
    max-width: auto;
    min-width: auto;
    align-items: center;
    text-align: center;
    margin: 32px 0;
  }
}
@media (max-width: 768px) {
  .home-page--hero-left {
    margin: 32px 0;
    flex: 0 0 100%;
  }
}
.home-page--hero-left h1 {
  width: 100%;
  max-width: 500px;
  margin-bottom: 8px;
  color: #004742;
}
@media (max-width: 1024px) {
  .home-page--hero-left h1 {
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .home-page--hero-left h1 {
    max-width: 100%;
  }
}
.home-page--hero-right {
  flex: 1;
  display: flex;
  justify-content: center;
  max-width: 720px;
  min-width: 500px;
  margin: 104px 0 -96px;
}
@media (max-width: 1024px) {
  .home-page--hero-right {
    margin: 32px 0 -96px;
  }
}
@media (max-width: 768px) {
  .home-page--hero-right {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
}
.home-page--hero-right img {
  width: 100%;
  height: auto;
}
@media (max-width: 1024px) {
  .home-page--hero-right img {
    max-width: 500px;
  }
}
.home-page--subtitle {
  width: 100%;
  max-width: 500px;
}
@media (max-width: 1024px) {
  .home-page--subtitle {
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .home-page--subtitle {
    max-width: 100%;
    font-size: 1.125rem;
    text-decoration: none;
    font-family: Chiswick Sans Text, sans-serif;
    font-weight: 300;
    letter-spacing: 0rem;
    line-height: 1.625rem;
    text-transform: none;
  }
}
.home-page--hero-buttons {
  display: flex;
  align-items: center;
  column-gap: 32px;
  margin-top: 32px;
}
.home-page--hero-buttons a {
  width: 200px;
}
.home-page--hero-buttons a:not(.button) {
  text-decoration: underline;
  width: fit-content;
}
@media (max-width: 1024px) {
  .home-page--hero-buttons {
    justify-content: center;
    column-gap: 16px;
  }
}
@media (max-width: 768px) {
  .home-page--hero-buttons {
    flex-direction: column;
    row-gap: 16px;
    column-gap: 16px;
    width: 100%;
  }
  .home-page--hero-buttons router-link,
  .home-page--hero-buttons a {
    width: 100%;
  }
}
.home-page--feature {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1320px;
  margin: 96px 0 0;
  padding: 0 88px;
}
@media (max-width: 768px) {
  .home-page--feature {
    margin: 32px 0 0;
    padding: 0 20px;
  }
}
.home-page--feature img {
  margin-top: 16px;
  max-width: 100%;
  border: 2px solid #f2eae9;
  border-radius: 16px;
}
@media (max-width: 768px) {
  .home-page--feature img {
    margin-top: 8px;
  }
}
.home-page--feature-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 768px) {
  .home-page--feature-top {
    text-align: left;
    flex-direction: column;
    align-items: flex-start;
  }
}
.home-page--feature-top p {
  margin: 16px 0 16px 48px;
}
@media (max-width: 768px) {
  .home-page--feature-top p {
    margin: 16px 0;
  }
}
.home-page--link {
  display: flex;
  align-items: center;
  column-gap: 32px;
  row-gap: 24px;
  width: 100%;
  max-width: 1320px;
  padding: 0 88px;
  margin-top: 96px;
}
@media (max-width: 1024px) {
  .home-page--link {
    align-items: flex-start;
  }
}
@media (max-width: 768px) {
  .home-page--link {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px;
  }
}
.home-page--link picture {
  width: 100%;
}
.home-page--link img {
  min-width: 350px;
  width: 100%;
  height: auto;
  border-radius: 16px;
  border: 1px solid #f2eae9;
}
@media (max-width: 768px) {
  .home-page--link img {
    min-width: 100%;
  }
}
.home-page--link-copy {
  min-width: 400px;
  width: 40%;
}
@media (max-width: 1024px) {
  .home-page--link-copy {
    min-width: 300px;
  }
}
@media (max-width: 768px) {
  .home-page--link-copy {
    min-width: 100%;
  }
}
.home-page--link-copy a {
  margin-top: 24px;
}
.home-page--proof {
  margin-top: 96px;
}
@media (max-width: 768px) {
  .home-page--proof {
    max-width: 100%;
  }
}
.home-page--proof h2 {
  max-width: 1320px;
  margin: 0 88px;
  color: #004742;
  text-align: center;
}
@media (max-width: 768px) {
  .home-page--proof h2 {
    max-width: 100%;
    margin: 0 20px;
  }
}
.home-page--proof-quotes {
  max-width: 1320px;
  display: flex;
  column-gap: 32px;
  row-gap: 32px;
  margin: 32px 88px 96px;
}
@media (max-width: 768px) {
  .home-page--proof-quotes {
    flex-direction: column;
    max-width: 100%;
    margin: 32px 20px 56px;
  }
}
.home-page--proof-quote {
  box-shadow: 0px 2px 8px 0px #0000000f;
  outline: 0;
  border-radius: 8px;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  padding: 24px 32px;
}
.home-page--proof-quote > p {
  color: #2f2f38;
}
.home-page--proof-quote > div {
  display: flex;
  margin-top: 16px;
}
.home-page--proof-quote > div img {
  width: 48px;
  height: 48px;
}
.home-page--proof-quote > div div {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
}
.home-page--proof-quote > div div p {
  margin: 0;
}
.home-page--letter {
  position: relative;
  z-index: 1;
  background: center bottom / cover no-repeat url(https://static.withcardinal.com/apps/known/home-page/2024-02-05/letter-back.webp);
  padding: 64px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .home-page--letter {
    padding: 32px 20px;
    background-position: right;
  }
}
@media only screen and (resolution >= 2dppx) {
  .home-page--letter {
    background: center bottom / cover no-repeat url(https://static.withcardinal.com/apps/known/home-page/2024-02-05/letter-back@2x.webp);
  }
  @media (max-width: 768px) {
    .home-page--letter {
      background-position: right;
    }
  }
}
@media only screen and (resolution >= 3dppx) {
  .home-page--letter {
    background: center bottom / cover no-repeat url(https://static.withcardinal.com/apps/known/home-page/2024-02-05/letter-back@3x.webp);
  }
  @media (max-width: 768px) {
    .home-page--letter {
      background-position: right;
    }
  }
}
.home-page--letter-inner {
  position: relative;
  width: 100%;
  max-width: 544px;
  padding: 40px 48px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 4px 16px 1px #00000014;
  outline: 0;
}
.home-page--letter-inner h2 {
  color: #004742;
}
.shop-labels-page marketing-header:not([data-scrolled]) .marketing-header--basket-items {
  border: 2px solid #f8c7bc;
}
.shop-labels-page--content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #FCF8F7;
  margin-top: -72px;
}
.shop-labels-page--hero {
  position: relative;
  margin: 0 20px;
  height: 752px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #131215;
  background: #FFCFC3;
}
@media (max-width: 768px) {
  .shop-labels-page--hero {
    width: 100%;
    border-radius: 0;
    padding: 96px 16px 64px;
    height: auto;
  }
}
.shop-labels-page--hero-left {
  height: 100%;
  flex: 1 1 100%;
  background-image: url(https://static.withcardinal.com/apps/known/home-page/2024-02-08/home-hero-left-full.png);
  background-repeat: no-repeat;
  background-position-x: right 120px;
  background-position-y: center;
  background-size: cover;
}
@media (max-width: 768px) {
  .shop-labels-page--hero-left {
    display: none;
  }
}
@media only screen and (resolution >= 2dppx) {
  .shop-labels-page--hero-left {
    background-image: url(https://static.withcardinal.com/apps/known/home-page/2024-02-08/home-hero-left-full@2x.png);
  }
}
@media only screen and (resolution >= 3dppx) {
  .shop-labels-page--hero-left {
    background-image: url(https://static.withcardinal.com/apps/known/home-page/2024-02-08/home-hero-left-full@3x.png);
  }
}
.shop-labels-page--hero-center {
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 550px;
  left: calc((100% - 550px) / 2);
}
@media (max-width: 768px) {
  .shop-labels-page--hero-center {
    position: static;
    width: 100%;
    margin: 32px 0;
    flex: 0 0 100%;
  }
}
.shop-labels-page--hero-center h1 {
  width: 100%;
  max-width: 598px;
  margin-bottom: 8px;
  color: #004742;
}
@media (max-width: 768px) {
  .shop-labels-page--hero-center h1 {
    max-width: 100%;
  }
}
.shop-labels-page--hero-right {
  height: 100%;
  flex: 1 1 100%;
  background-image: url(https://static.withcardinal.com/apps/known/home-page/2024-02-08/home-hero-right-full.png);
  background-position-x: left 50px;
  background-position-y: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 768px) {
  .shop-labels-page--hero-right {
    display: none;
  }
}
@media only screen and (resolution >= 2dppx) {
  .shop-labels-page--hero-right {
    background-image: url(https://static.withcardinal.com/apps/known/home-page/2024-02-08/home-hero-right-full@2x.png);
  }
}
@media only screen and (resolution >= 3dppx) {
  .shop-labels-page--hero-right {
    background-image: url(https://static.withcardinal.com/apps/known/home-page/2024-02-08/home-hero-right-full@3x.png);
  }
}
.shop-labels-page--subtitle {
  margin: 0 auto 24px;
  width: 100%;
  max-width: 477px;
}
@media (max-width: 768px) {
  .shop-labels-page--subtitle {
    font-size: 1.125rem;
    text-decoration: none;
    font-family: Chiswick Sans Text, sans-serif;
    font-weight: 300;
    letter-spacing: 0rem;
    line-height: 1.625rem;
    text-transform: none;
  }
}
.shop-labels-page--hero-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
}
.shop-labels-page--hero-buttons router-link,
.shop-labels-page--hero-buttons a {
  width: 200px;
}
@media (max-width: 768px) {
  .shop-labels-page--hero-buttons {
    flex-direction: column;
    row-gap: 16px;
    width: 100%;
  }
  .shop-labels-page--hero-buttons router-link,
  .shop-labels-page--hero-buttons a {
    width: 100%;
  }
}
.shop-labels-page--benefits {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 64px 0 40px;
}
@media (max-width: 768px) {
  .shop-labels-page--benefits {
    margin: 0 20px;
  }
}
.shop-labels-page--benefits h2 {
  text-align: center;
  margin-bottom: 80px;
}
@media (max-width: 768px) {
  .shop-labels-page--benefits h2 {
    margin-bottom: 56px;
    padding: 0 16px;
  }
}
.shop-labels-page--benefits-wrap {
  max-width: 1368px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 48px;
  row-gap: 40px;
  padding: 0 32px;
}
@media (max-width: 768px) {
  .shop-labels-page--benefits-wrap {
    flex-direction: column;
    padding: 0 16px;
  }
}
.shop-labels-page--benefit {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  max-width: 450px;
  margin-bottom: 64px;
}
@media (max-width: 768px) {
  .shop-labels-page--benefit {
    width: 100%;
    margin-bottom: 0;
  }
}
.shop-labels-page--benefit div {
  background: #ffe9e5;
  height: 72px;
  width: 72px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}
.shop-labels-page--benefit div svg {
  height: 32px;
  width: 32px;
  color: #e1776b;
}
.shop-labels-page--benefit h3,
.shop-labels-page--benefit p {
  width: 100%;
  text-align: center;
  margin: 0;
}
.shop-labels-page--benefit h3 {
  margin-bottom: 4px;
}
.shop-labels-page--app {
  display: flex;
  width: 100%;
  max-width: 1680px;
  justify-content: flex-start;
  column-gap: 64px;
  margin-bottom: 132px;
}
@media (max-width: 768px) {
  .shop-labels-page--app {
    flex-direction: column;
    margin-bottom: 64px;
  }
}
.shop-labels-page--app-left {
  flex: 1 1 50%;
}
.shop-labels-page--app-left img {
  width: 100%;
  max-width: 786px;
  height: auto;
}
.shop-labels-page--app-right {
  flex: 1 1 50%;
  padding: 128px 88px 0 0;
}
@media (max-width: 768px) {
  .shop-labels-page--app-right {
    padding: 64px 20px 0;
  }
}
.shop-labels-page--app-right-inner {
  max-width: 600px;
}
.shop-labels-page--app-right-inner h2 {
  color: #004742;
}
.shop-labels-page--app-highlights {
  display: flex;
  flex-direction: column;
  row-gap: 56px;
  margin-top: 64px;
}
.shop-labels-page--app-highlight {
  display: flex;
  column-gap: 32px;
}
.shop-labels-page--app-highlight div:first-child {
  background: #ffe9e5;
  height: 80px;
  flex: 0 0 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
}
.shop-labels-page--app-highlight div:first-child svg {
  height: 32px;
  width: 32px;
  color: #e1776b;
}
.shop-labels-page--app-highlight h3 {
  color: #004742;
}
.shop-labels-page--app-highlight p {
  margin: 4px 0 0;
}
@media (max-width: 768px) {
  .shop-labels-page--proof {
    max-width: 100%;
  }
}
.shop-labels-page--proof h2 {
  max-width: 1320px;
  margin: 0 88px;
  color: #004742;
  text-align: center;
}
@media (max-width: 768px) {
  .shop-labels-page--proof h2 {
    max-width: 100%;
    margin: 0 20px;
  }
}
.shop-labels-page--proof-quotes {
  max-width: 1320px;
  display: flex;
  column-gap: 32px;
  row-gap: 32px;
  margin: 32px 88px 96px;
}
@media (max-width: 768px) {
  .shop-labels-page--proof-quotes {
    flex-direction: column;
    max-width: 100%;
    margin: 32px 20px 56px;
  }
}
.shop-labels-page--proof-quote {
  box-shadow: 0px 2px 8px 0px #0000000f;
  outline: 0;
  border-radius: 8px;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  padding: 24px 32px;
}
.shop-labels-page--proof-quote > p {
  color: #2f2f38;
}
.shop-labels-page--proof-quote > div {
  display: flex;
  margin-top: 16px;
}
.shop-labels-page--proof-quote > div img {
  width: 48px;
  height: 48px;
}
.shop-labels-page--proof-quote > div div {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
}
.shop-labels-page--proof-quote > div div p {
  margin: 0;
}
.shop-labels-page--letter {
  position: relative;
  z-index: 1;
  background: center bottom / cover no-repeat url(https://static.withcardinal.com/apps/known/home-page/2024-02-05/letter-back.webp);
  padding: 64px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .shop-labels-page--letter {
    padding: 32px 20px;
    background-position: right;
  }
}
@media only screen and (resolution >= 2dppx) {
  .shop-labels-page--letter {
    background: center bottom / cover no-repeat url(https://static.withcardinal.com/apps/known/home-page/2024-02-05/letter-back@2x.webp);
  }
  @media (max-width: 768px) {
    .shop-labels-page--letter {
      background-position: right;
    }
  }
}
@media only screen and (resolution >= 3dppx) {
  .shop-labels-page--letter {
    background: center bottom / cover no-repeat url(https://static.withcardinal.com/apps/known/home-page/2024-02-05/letter-back@3x.webp);
  }
  @media (max-width: 768px) {
    .shop-labels-page--letter {
      background-position: right;
    }
  }
}
.shop-labels-page--letter-inner {
  position: relative;
  width: 100%;
  max-width: 544px;
  padding: 40px 48px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 4px 16px 1px #00000014;
  outline: 0;
}
.shop-labels-page--letter-inner h2 {
  color: #004742;
}
marketing-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  padding: 0 40px;
  background: #ffffff;
  margin-bottom: 16px;
  position: sticky;
  top: 0;
  z-index: 1000;
}
marketing-header a {
  color: #000000;
}
@media (max-width: 768px) {
  marketing-header {
    padding: 0 16px;
  }
}
marketing-header[data-magic] {
  background: transparent;
  margin-bottom: 0;
  transition: background 0.5s;
}
@media (max-width: 768px) {
  marketing-header[data-magic=no-mobile] {
    background: #ffffff;
    margin-bottom: 16px;
  }
}
marketing-header[data-magic][data-scrolled] {
  background: #ffffff;
}
.marketing-header--left {
  display: flex;
  align-items: center;
  column-gap: 40px;
  flex: 1 1 25%;
}
.marketing-header--left a {
  white-space: nowrap;
}
@media (max-width: 768px) {
  .marketing-header--left {
    display: none;
  }
}
.marketing-header--left-mobile {
  display: none;
}
@media (max-width: 768px) {
  .marketing-header--left-mobile {
    display: flex;
  }
}
.marketing-header--left-mobile button {
  border: 0;
  background: transparent;
  height: 24px;
  width: 24px;
  padding: 0;
  cursor: pointer;
}
.marketing-header--left-mobile button svg {
  height: 100%;
  width: 100%;
  color: #131215;
}
.marketing-header--logo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 50%;
  color: #131215;
  font-family: Chiswick Text, serif;
  font-size: 2rem;
  line-height: 2.2rem;
}
@media (max-width: 768px) {
  .marketing-header--logo {
    font-size: 1.75rem;
    line-height: 1.925rem;
  }
}
.marketing-header--logo a {
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.marketing-header--logo svg {
  height: 20px;
  width: 112px;
  color: #004742;
}
@media (max-width: 768px) {
  .marketing-header--logo svg {
    height: 18px;
    width: 98px;
  }
}
.marketing-header--right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 24px;
  color: #000000;
  flex: 1 1 25%;
}
@media (max-width: 768px) {
  .marketing-header--right {
    flex: 0 0 auto;
  }
}
.marketing-header--right a {
  display: flex;
  align-items: center;
}
.marketing-header--right a span {
  margin-left: 8px;
}
.marketing-header--right svg {
  height: 24px;
  width: 24px;
}
.marketing-header--mobile-close-url svg {
  height: 24px;
  width: 24px;
}
.marketing-header--basket-items {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-family: Chiswick Deck, sans-serif;
  margin-left: -8px;
  margin-top: -15px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  color: #ffffff;
  background: #f0877b;
}
.marketing-header--announcement {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  background: #e1776b;
  color: #ffe9e5;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.marketing-header--announcement a {
  margin-left: 8px;
  font-weight: 400;
  color: #ffe9e5;
  text-decoration: underline;
}
.legal-page {
  max-width: 800px;
  padding: 80px 32px;
}
@media (max-width: 768px) {
  .legal-page {
    padding: 0 16px;
  }
}
.legal-page table {
  margin-bottom: 40px;
  border: 1px solid #131215;
  border-collapse: collapse;
}
.legal-page td,
.legal-page th {
  padding: 8px 16px;
  border: 1px solid #131215;
}
.marketing-footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #082e2b;
  color: #cef2f0;
  padding: 64px 80px 32px;
}
@media (max-width: 1024px) {
  .marketing-footer {
    padding: 48px 48px;
  }
}
@media (max-width: 768px) {
  .marketing-footer {
    padding: 32px 20px 16px;
  }
}
.marketing-footer a {
  color: #cef2f0;
}
.marketing-footer--top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;
}
@media (max-width: 1024px) {
  .marketing-footer--top {
    flex-direction: column;
    margin-bottom: 40px;
  }
}
.marketing-footer--top-left {
  display: flex;
  column-gap: 98px;
}
@media (max-width: 1024px) {
  .marketing-footer--top-left {
    flex-direction: column;
    row-gap: 16px;
  }
}
.marketing-footer--brand {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
@media (max-width: 1024px) {
  .marketing-footer--brand {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 48px;
  }
}
@media (max-width: 768px) {
  .marketing-footer--brand {
    flex-direction: column;
  }
}
.marketing-footer--brand svg {
  width: auto;
  height: 20px;
  color: #ffffff;
}
.marketing-footer--brand a {
  display: block;
}
.marketing-footer--brand flex-row {
  column-gap: 16px;
}
.marketing-footer--brand flex-row svg {
  color: #cef2f0;
}
.marketing-footer--top-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 104px;
}
@media (max-width: 1024px) {
  .marketing-footer--top-right {
    justify-content: flex-start;
    column-gap: 64px;
  }
}
.marketing-footer--column {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  white-space: nowrap;
}
.marketing-footer--column a {
  display: block;
  color: #cef2f0;
}
.marketing-footer--column-title {
  color: #ffffff;
}
@media (max-width: 1024px) {
  .marketing-footer--column-title {
    text-align: left;
    width: 100%;
  }
}
.marketing-footer--subscribe {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 24px;
}
@media (max-width: 1024px) {
  .marketing-footer--subscribe {
    margin-bottom: 64px;
  }
}
.marketing-footer--subscribe div:first-child {
  max-width: 350px;
  margin-right: 32px;
  color: #ffffff;
}
.marketing-footer--subscribe better-form {
  width: 100%;
  max-width: 395px;
}
@media (max-width: 1024px) {
  .marketing-footer--subscribe better-form {
    max-width: 100%;
  }
}
.marketing-footer--subscribe input {
  border-radius: 999px;
  height: 52px;
  border: 0;
  width: 100%;
  margin-right: 12px;
  padding: 16px 20px;
  outline: 0;
  font-size: 0.9375rem;
  text-decoration: none;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 300;
  letter-spacing: 0rem;
  line-height: 1.375rem;
  text-transform: none;
}
.marketing-footer--subscribe input ::placeholder {
  color: #7a7986;
}
.marketing-footer--subscribe button {
  height: 52px !important;
  width: 52px !important;
}
.marketing-footer--subscribe button[data-submitting=true] .spinner {
  display: none;
}
.marketing-footer--bottom {
  align-items: flex-end;
  justify-content: space-between;
}
.marketing-footer--bottom > div {
  display: flex;
  flex-direction: column;
}
@media (max-width: 768px) {
  .marketing-footer--bottom > div {
    width: 100%;
  }
}
.marketing-footer--bottom svg {
  margin-bottom: 4px;
}
@media (max-width: 768px) {
  .marketing-footer--bottom svg {
    display: none;
  }
}
.marketing-footer--copyright {
  display: flex;
  margin-bottom: 8px;
  color: #ffffff;
}
.marketing-footer--links {
  display: flex;
  column-gap: 16px;
}
.marketing-footer--links router-link {
  display: flex;
}
.help-page {
  padding: 80px 32px;
  flex: 1;
  max-width: 800px;
}
@media (max-width: 768px) {
  .help-page {
    padding: 0 16px;
  }
}
.help-page h2 {
  margin-top: 32px;
}
.help-page h3 {
  margin: 24px 0;
}
.help-page table {
  margin-bottom: 40px;
  border: 1px solid #131215;
  border-collapse: collapse;
}
.help-page td,
.help-page th {
  padding: 8px 16px;
  border: 1px solid #131215;
}
.help-page a {
  text-decoration: underline;
}
.help-page svg {
  vertical-align: middle;
}
.help-page strong {
  font-weight: 400;
  color: #005a54;
}
.shop-page marketing-header:not([data-scrolled]) .marketing-header--basket-items {
  border: 2px solid #ffe9e5;
}
.shop-page--content {
  margin-top: -72px;
  flex: 1;
  align-self: center;
  width: 100%;
}
@media (max-width: 768px) {
  .shop-page--content {
    margin-top: -16px;
  }
}
.shop-page--see-sample {
  margin: 80px 0 40px 0;
}
@media (max-width: 768px) {
  .shop-page--see-sample {
    margin: 0 0 40px 0;
  }
}
.shop-page form {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .shop-page form {
    margin-bottom: 32px;
  }
}
.shop-page form button[type=submit] {
  margin-top: 20px;
  border-radius: 999px;
}
.shop-page form .shop-page--error-label {
  color: #c91737;
}
.shop-page--variants {
  margin-top: 4px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 14px;
  row-gap: 8px;
  margin-bottom: 20px;
}
.shop-page--variants input {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background: #ffffff;
  height: 32px;
  width: 32px;
  border-radius: 999px;
  outline-offset: 2px;
  margin: 0;
}
.shop-page--variants input:checked {
  outline: 2px solid #f0877b;
}
.shop-page--variants input[data-title="Chocolate Brown"] {
  background: #5A3322;
}
.shop-page--variants input[data-title="Grass Green"] {
  background: #34B346;
}
.shop-page--variants input[data-title=Beige] {
  background: #E3BDA0;
}
.shop-page--variants input[data-title=Black] {
  background: #000;
}
.shop-page--variants input[data-title="Limited Edition Lilac"] {
  background: #AB9FE1;
}
.shop-page--variants input[data-title="Limited Edition Light Blue"] {
  background: #A4CFC1;
}
.shop-page--variants input[data-title="Limited Edition Light Pink"] {
  background: #F3CFC3;
}
.shop-page--quantity {
  display: flex;
  flex-wrap: wrap;
  column-gap: 12px;
  row-gap: 8px;
  margin-top: 4px;
}
.shop-page--quantity label {
  cursor: pointer;
  position: relative;
  display: flex;
  height: 40px;
  width: 40px;
}
.shop-page--quantity div {
  height: 40px;
  width: 40px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Chiswick Grotesque, sans-serif;
  font-weight: 500;
}
.shop-page--quantity input {
  display: flex;
  -webkit-appearance: none;
  appearance: none;
  background: #ffffff;
  height: 40px;
  width: 40px;
  border-radius: 999px;
  margin: 0;
  border: 1px solid #fadbd2;
}
.shop-page--quantity input:checked {
  border: 1px solid #ffffff;
  outline: 2px solid #f0877b;
  color: #f0877b;
}
.shop-page--quantity input:checked + div {
  color: #f0877b;
  font-weight: 600;
}
.shop-page--icons {
  margin-top: 48px;
  border-top: 1px solid #EBD5CE;
  padding: 34px 16px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.shop-page--icons div {
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.shop-page--icons div svg {
  color: #e1776b;
  height: 24px;
  width: 24px;
}
.shop-page--icons div p {
  margin: 8px 0 0;
  white-space: nowrap;
}
.shop-page--three-col {
  display: flex;
  padding: 0 80px;
  margin: 88px 0 144px;
  column-gap: 88px;
  row-gap: 32px;
}
@media (max-width: 1024px) {
  .shop-page--three-col {
    flex-direction: column;
    margin: 64px 0;
    padding: 0 144px;
  }
}
@media (max-width: 768px) {
  .shop-page--three-col {
    padding: 0 20px;
  }
}
.shop-page--three-col-col {
  flex-basis: 33%;
}
.shop-page--three-col-col h2 {
  color: #004742;
}
.shop-page--three-col-col ul {
  padding-left: 28px;
}
.shop-page--three-col-col li {
  padding: 8px;
}
.shop-page--instructions {
  width: 100%;
  margin-bottom: 149px;
  display: flex;
}
@media (max-width: 1024px) {
  .shop-page--instructions {
    flex-direction: column-reverse;
    margin-bottom: 64px;
  }
}
.shop-page--instructions-left {
  flex: 1 1 50%;
  padding: 28px 32px 0 80px;
  display: flex;
  flex-direction: column;
  row-gap: 72px;
}
@media (max-width: 1024px) {
  .shop-page--instructions-left {
    padding: 64px 144px 0;
    row-gap: 44px;
  }
}
@media (max-width: 768px) {
  .shop-page--instructions-left {
    padding: 64px 20px 0;
  }
}
.shop-page--instructions-right {
  flex: 1 1 50%;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1024px) {
  .shop-page--instructions-right {
    flex: 1 1 100%;
    min-width: 100%;
  }
}
@media (max-width: 1024px) {
  .shop-page--instructions-split {
    display: none;
  }
}
.shop-page--instructions-full {
  display: none;
}
@media (max-width: 1024px) {
  .shop-page--instructions-full {
    display: block;
    width: 100%;
    height: auto;
  }
}
.shop-page--instructions-full picture,
.shop-page--instructions-full img {
  width: 100%;
  object-fit: cover;
  object-position: right;
}
.shop-page--instructions-step {
  display: flex;
  column-gap: 32px;
}
@media (max-width: 768px) {
  .shop-page--instructions-step {
    column-gap: 20px;
  }
}
.shop-page--instructions-step div:first-child {
  flex: 0 0 90px;
  height: 90px;
  border-radius: 999px;
  background: #ffe9e5;
  color: #f0877b;
  font-size: 38px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Holen Vintage, serif;
}
@media (max-width: 768px) {
  .shop-page--instructions-step div:first-child {
    flex: 0 0 56px;
    height: 56px;
    font-size: 32px;
  }
}
.shop-page--instructions-step div:last-child {
  max-width: 554px;
}
.shop-page--instructions-step h2 {
  color: #004742;
  margin-bottom: 12px;
}
.shop-page--instructions-step p {
  margin: 0;
}
.shop-page--details {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 2400px;
  column-gap: 20px;
  background-image: url(https://static.withcardinal.com/apps/known/indoor-labels/2024-02-06/indoor-pattern.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (resolution >= 2dppx) {
  .shop-page--details {
    background-image: url(https://static.withcardinal.com/apps/known/indoor-labels/2024-02-06/indoor-pattern@2x.jpg);
  }
}
@media only screen and (resolution >= 3dppx) {
  .shop-page--details {
    background-image: url(https://static.withcardinal.com/apps/known/indoor-labels/2024-02-06/indoor-pattern@3x.jpg);
  }
}
.shop-page--details[data-product=outdoor] {
  background-image: url(https://static.withcardinal.com/apps/known/outdoor-labels/2024-02-06-2/outdoor-pattern.jpg);
}
@media only screen and (resolution >= 2dppx) {
  .shop-page--details[data-product=outdoor] {
    background-image: url(https://static.withcardinal.com/apps/known/outdoor-labels/2024-02-06-2/outdoor-pattern@2x.jpg);
  }
}
@media only screen and (resolution >= 3dppx) {
  .shop-page--details[data-product=outdoor] {
    background-image: url(https://static.withcardinal.com/apps/known/outdoor-labels/2024-02-06-2/outdoor-pattern@3x.jpg);
  }
}
@media (max-width: 1024px) {
  .shop-page--details {
    flex-direction: column;
    padding: 20px 0;
  }
}
@media (max-width: 768px) {
  .shop-page--details {
    padding: 20px;
  }
}
.shop-page--details-column {
  margin: 80px 0;
  flex: 1 1 50%;
  max-width: 596px;
  background: #fff5f5;
  padding: 56px;
  box-shadow: 0px 4px 16px 1px #00000014;
  outline: 0;
}
@media (max-width: 1024px) {
  .shop-page--details-column {
    max-width: 100%;
    margin: 0 144px;
  }
  .shop-page--details-column:not(:first-child) {
    margin-top: 20px;
  }
}
@media (max-width: 768px) {
  .shop-page--details-column {
    margin: 0;
  }
}
.shop-page--details-column h2 {
  color: #004742;
  margin-bottom: 16px;
}
.shop-page--details-column h2:not(:first-child) {
  margin-top: 48px;
}
.shop-page--details-column p {
  margin: 0;
}
.shop-page--detail-set {
  margin: 4px 0 16px;
}
.shop-page--detail-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 8px;
}
@media (max-width: 768px) {
  .shop-page--detail-row {
    flex-direction: column;
  }
}
.shop-page--detail-row:first-child {
  padding-top: 16px;
  line-height: 2rem;
}
.shop-page--detail-row div:last-child {
  text-align: right;
}
@media (max-width: 768px) {
  .shop-page--detail-row div:last-child {
    text-align: left;
    margin-bottom: 8px;
  }
}
.shop-page--detail-row div:last-child div {
  line-height: 24px;
}
.shop-basket-page {
  width: 100%;
  margin-top: -72px;
  min-height: 100vh;
}
.shop-basket-page--split {
  display: flex;
}
@media (max-width: 1024px) {
  .shop-basket-page--split {
    flex-direction: column;
  }
}
.shop-basket-page--left {
  padding: 172px 0 0 0;
  flex: 1 1 64.5%;
  min-height: 100vh;
}
@media (max-width: 1024px) {
  .shop-basket-page--left {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 100%;
    padding: 120px 20px 32px 20px;
    min-height: auto;
  }
}
.shop-basket-page--left-inner {
  padding: 0 80px 0 62px;
  color: #004742;
}
@media (max-width: 1024px) {
  .shop-basket-page--left-inner {
    padding: 0;
    width: 100%;
    max-width: 640px;
  }
}
.shop-basket-page--product {
  display: flex;
  column-gap: 24px;
  border-bottom: 1px solid #f2eae9;
  padding: 40px 0;
}
.shop-basket-page--product:first-child {
  padding-top: 0;
}
@media (max-width: 1024px) {
  .shop-basket-page--product {
    align-items: center;
  }
  .shop-basket-page--product:last-child {
    border-bottom: 0;
  }
}
.shop-basket-page--product img {
  width: 120px;
  height: 120px;
  border-radius: 999px;
}
@media (max-width: 1024px) {
  .shop-basket-page--product img {
    width: 96px;
    height: 96px;
  }
}
.shop-basket-page--product-right {
  width: 100%;
}
.shop-basket-page--product-title-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.shop-basket-page--product-title-row div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.shop-basket-page--remove-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0;
  background: transparent;
  cursor: pointer;
}
.shop-basket-page--quantity-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 18px;
}
@media (max-width: 768px) {
  .shop-basket-page--quantity-row {
    flex-direction: row-reverse;
  }
}
.shop-basket-page--price-original {
  text-decoration: line-through;
}
.shop-basket-page--price-override {
  color: #e1776b;
}
.shop-basket-page--quantity {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  width: 125px;
  padding: 16px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #f2eae9;
  box-shadow: 0px 1px 2px 0px #0000000a;
  outline: 0;
}
@media (max-width: 768px) {
  .shop-basket-page--quantity {
    height: 38px;
    padding: 8px 12px;
  }
}
.shop-basket-page--quantity button {
  display: flex;
  align-items: center;
  border: 0;
  background: transparent;
  height: 16px;
  width: 16px;
  padding: 0;
  cursor: pointer;
}
.shop-basket-page--quantity button svg {
  color: #131215;
  width: 100%;
  height: 100%;
}
.shop-basket-page--right {
  padding: 172px 0 40px 0;
  flex: 1 1 35.5%;
  background: #ffffff;
  min-height: 100vh;
  min-width: 460px;
}
@media (max-width: 1024px) {
  .shop-basket-page--right {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 100%;
    min-height: auto;
    min-width: auto;
    padding: 40px 20px 80px 20px;
  }
}
.shop-basket-page--right-inner {
  display: flex;
  flex-direction: column;
  margin: 0 80px;
  max-width: 378px;
  min-width: 300px;
  height: 100%;
  color: #004742;
}
@media (max-width: 1024px) {
  .shop-basket-page--right-inner {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 640px;
  }
}
.shop-basket-page--details {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  border-bottom: 1px solid #dfdfe1;
  padding: 32px 0 24px 0;
  margin-bottom: 24px;
}
.shop-basket-page--details-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.shop-basket-page--right-row {
  padding-bottom: 16px;
  display: flex;
  alight-items: center;
  justify-content: space-between;
}
.shop-basket-page--checkout {
  flex: 1 1 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  border-top: 1px solid #dfdfe1;
  margin-top: 8px;
  padding-top: 24px;
}
@media (max-width: 1024px) {
  .shop-basket-page--checkout {
    border-top: 0;
  }
}
body.client-router-loading .shop-basket-page--checkout .spinner {
  display: flex;
}
.shop-basket-page--by-checking-out {
  font-size: 0.6875rem;
  color: #50505e;
  text-align: center;
  margin: 20px auto 0;
}
@media (max-width: 1024px) {
  .shop-basket-page--by-checking-out {
    width: 100%;
  }
}
.shop-basket-page--by-checking-out a {
  text-decoration: underline;
  color: #50505e;
}
.shop-basket-page--empty-container {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: min(25%, 104px);
  padding-bottom: 200px;
}
@media (max-width: 768px) {
  .shop-basket-page--empty-container {
    justify-content: center;
    padding-top: 0;
  }
}
.shop-basket-page--empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 452px;
  max-width: 452px;
  border-radius: 4px;
  padding: 32px 48px;
}
@media (max-width: 768px) {
  .shop-basket-page--empty {
    padding: 0;
    width: 100%;
    max-width: 100%;
    background: none;
    border-radius: 0;
  }
}
.shop-basket-page--empty svg {
  color: #f0877b;
  width: 72px;
  height: 72px;
  margin-bottom: 24px;
}
.shop-basket-page--empty h2,
.shop-basket-page--empty p,
.shop-basket-page--empty router-link {
  margin: 0;
  width: 100%;
  text-align: center;
}
.shop-basket-page--empty h2 {
  margin-bottom: 4px;
}
.shop-basket-page--empty p {
  margin-bottom: 20px;
}
shop-page-hero {
  display: flex;
}
@media (max-width: 1024px) {
  shop-page-hero {
    flex-direction: column;
  }
}
.shop-page-hero--left {
  flex: 1 1 70%;
  display: flex;
  position: relative;
}
@media (max-width: 768px) {
  .shop-page-hero--left {
    flex: 1 1 auto;
  }
}
.shop-page-hero--left picture,
.shop-page-hero--left img {
  position: absolute;
  right: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  aspect-ratio: 1015 / 662;
  object-fit: cover;
  object-position: center;
}
@media (max-width: 1024px) {
  .shop-page-hero--left picture,
  .shop-page-hero--left img {
    position: static;
    min-width: 100%;
    width: 100%;
    height: auto;
  }
}
.shop-page-hero--right {
  flex: 1 1 36%;
  min-width: 454px;
  background: #ffe9e5;
  padding: 88px clamp(72px, 8.6%, 20px) 0;
}
@media (max-width: 768px) {
  .shop-page-hero--right {
    padding: 44px 20px 0;
    min-width: auto;
  }
}
.shop-page-hero--right h1 {
  text-align: left;
  margin: 0;
  color: #004742;
}
.shop-page-hero--right p {
  margin: 12px 0 32px;
}
.settings-page h1 {
  margin-bottom: 16px;
}
.settings-page--group {
  background: #ffffff;
  border: 1px solid #F4ECE9;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px #0000000a;
  outline: 0;
  padding: 0 16px;
}
.settings-page--item {
  border-top: 1px solid #f2eae9;
}
.settings-page--item a {
  color: #131215;
}
.settings-page--item:first-child {
  border-top: none;
}
.settings-page--item-inner {
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border: 0;
  width: 100%;
  text-align: left;
  cursor: pointer;
}
.settings-page--item-text {
  font-size: 15px;
  color: #50505e;
}
flex-row {
  display: flex;
  flex-direction: row;
}
flex-col {
  display: flex;
  flex-direction: column;
}
:is(flex-row, flex-col)[align-items=center] {
  align-items: center;
}
:is(flex-row, flex-col)[justify-content=center] {
  justify-content: center;
}
:is(flex-row, flex-col)[justify-content=space-between] {
  justify-content: space-between;
}
:is(flex-row, flex-col)[justify-content=space-around] {
  justify-content: space-around;
}
:is(flex-row, flex-col)[justify-content=flex-start] {
  justify-content: flex-start;
}
:is(flex-row, flex-col)[justify-content=flex-end] {
  justify-content: flex-end;
}
:is(flex-row, flex-col)[wrap] {
  flex-wrap: wrap;
}
:is(flex-row, flex-col)[fill] {
  width: 100%;
}
:is(flex-row, flex-col)[vfill] {
  height: 100%;
}
overflow-menu {
  display: flex;
  align-items: center;
}
overflow-menu button {
  display: flex;
  align-items: center;
  border: 0;
  background: 0;
  color: #131215;
  cursor: pointer;
}
overflow-menu svg {
  width: 20px;
  height: 20px;
}
overflow-menu dialog {
  padding: 0;
  border: none;
  border-radius: 4px;
  box-shadow: 0px 4px 16px 1px #00000014;
  outline: 0;
  margin: 0;
  min-width: min(80%, 180px);
}
overflow-menu dialog::backdrop {
  background: none;
}
.overflow-menu--container {
  padding: 12px 0;
}
.overflow-menu--item {
  display: flex;
  align-items: center;
}
.overflow-menu--item router-link {
  width: 100%;
}
.overflow-menu--item a {
  flex: 1;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  outline: 0;
}
.overflow-menu--item a:focus,
.overflow-menu--item a:hover {
  background: #f2eae9;
}
.page-error--container {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: min(25%, 104px);
}
@media (max-width: 768px) {
  .page-error--container {
    justify-content: center;
    padding-top: min(10%, 104px);
  }
}
.page-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 452px;
  max-width: 452px;
  background: #fff5f5;
  border-radius: 4px;
  padding: 32px 48px;
}
@media (max-width: 768px) {
  .page-error {
    padding: 0;
    width: 100%;
    max-width: 100%;
    background: none;
    border-radius: 0;
  }
}
.page-error svg {
  color: #f0877b;
  width: 72px;
  height: 72px;
  margin-bottom: 24px;
}
.page-error h2,
.page-error p {
  margin: 0;
  width: 100%;
  text-align: center;
}
.page-error h2 {
  margin-bottom: 4px;
}
.page-error p {
  margin-bottom: 20px;
}
.page-error--stack-trace {
  width: 100%;
}
.move-label-page {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.move-label-page header {
  margin-bottom: 16px;
}
.move-label-page p {
  margin: 0;
}
.move-label-page--or {
  text-align: center;
}
journal-form {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.plant-heading {
  margin: 0 0 20px 0;
}
.plant-heading p {
  color: #2f2f38;
  margin: 0;
}
@media (max-width: 768px) {
  .plant-heading p {
    text-align: center;
  }
}
.plant-heading--title-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .plant-heading--title-row {
    justify-content: center;
    text-align: center;
  }
}
.plant-updates-page--update {
  padding: 32px 0;
  border-bottom: 1px solid #f2eae9;
}
.plant-updates-page--update:first-child {
  padding-top: 0;
}
.plant-updates-page--update:last-child {
  border-bottom: none;
}
.plant-updates-page--update-date {
  color: #383945;
  margin-bottom: 4px;
}
.plant-updates-page--update-message p {
  margin: 12px 0;
}
.plant-updates-page--update-message p:last-child {
  margin-bottom: 0;
}
.plant-updates-page--update-images {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;
}
.plant-updates-page--update-images image-zoom {
  max-width: calc(50% - 4px);
}
.plant-updates-page--update-images img[data-thumbnail] {
  max-width: 100%;
  aspect-ratio: 4 / 3;
  object-fit: cover;
  border-radius: 4px;
}
.plant-updates-page--no-updates {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px 40px;
  border: 1px dashed #edd0cc;
  border-radius: 4px;
  height: 400px;
}
.plant-updates-page--no-updates button {
  margin-top: 24px;
}
.plant-tabs {
  display: flex;
  justify-content: space-between;
  background: #f2eae9;
  padding: 4px;
  margin-bottom: 20px;
  border-radius: 999px;
}
.plant-tabs router-link {
  flex: 1 1 0;
}
.plant-tabs router-link a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  height: 48px;
}
.plant-tabs router-link a[data-selected] {
  color: #004742;
  background: #ffffff;
  border-radius: 999px;
}
.email-subscribe-page {
  margin-top: 32px;
  margin-bottom: 128px;
}
@media (max-width: 768px) {
  .email-subscribe-page {
    padding: 0 16px;
  }
}
.email-subscribe-page form {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.email-subscribe-page p {
  text-align: center;
}
.email-subscribe-page a {
  text-decoration: underline;
}
.category-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 32px 0;
}
.category-page h1 {
  margin-bottom: 12px;
}
.category-page p {
  max-width: 478px;
  margin-bottom: 24px;
}
.category-page--categories {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.category-page--category {
  width: 100%;
  max-width: 478px;
  overflow: hidden;
  border: 1px solid #e9e9ea;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px #0000000a;
  outline: 0;
}
.category-page--category:hover {
  border-color: #bbbbbf;
}
.category-page--category img {
  width: 100%;
  border-radius: 4px 4px 0 0;
  display: block;
}
.category-page--category h2 {
  padding: 20px 0 20px 16px;
}
color-select {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
mobile-menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: -100%;
  right: 100%;
  bottom: 0;
  background: #e1776b;
  z-index: 2000;
  transition: 0.3s;
  padding: 20px;
}
mobile-menu button {
  border: 0;
  padding: 0;
  color: #ffffff;
  background: transparent;
  cursor: pointer;
  height: 24px;
  width: 24px;
}
mobile-menu button:hover,
mobile-menu button:focus {
  color: #fadbd2;
}
mobile-menu button svg {
  height: 100%;
  width: 100%;
}
mobile-menu flex-col {
  margin-top: 80px;
}
mobile-menu router-link,
mobile-menu a {
  display: block;
  text-align: center;
  color: #ffffff;
}
mobile-menu a {
  padding: 20px 0;
}
mobile-menu a:hover,
mobile-menu a:focus {
  color: #ffe9e5;
}
.mobile-menu--button-row a.button:hover {
  background: #fadbd2;
  color: #000000;
}
body.mobile-menu-open mobile-menu {
  left: 0;
  right: 0;
}
journal-form-images {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 16px;
}
journal-form-images[data-preprocessing=true] .journal-form-images--delete {
  display: none;
}
journal-form-images input[type=file] {
  display: none;
}
.journal-form-images--image {
  position: relative;
  flex: 0 0 calc(50% - 8px);
  aspect-ratio: 4 / 3;
  border-radius: 4px;
  overflow: hidden;
}
.journal-form-images--image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: #ffffff;
}
.journal-form-images--image .spinner {
  position: absolute;
  bottom: 8px;
  right: 8px;
  opacity: 0;
}
.journal-form-images--add {
  flex: 0 0 calc(50% - 8px);
  aspect-ratio: 4 / 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #edd0cc;
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
}
.journal-form-images--add:nth-child(6) {
  display: none;
}
.journal-form-images--delete {
  position: absolute;
  right: 8px;
  bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  height: 24px;
  width: 24px;
  border-radius: 4px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  border: 0;
  box-shadow: 0px 4px 16px 1px #00000014;
  outline: 0;
}
.journal-form-images--delete svg {
  width: 100%;
  height: 100%;
}
image-zoom button {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  padding: 0;
  margin: 0;
  border: none;
  outline: 0;
}
image-zoom button:focus-visible {
  outline: auto;
}
image-zoom dialog {
  background: transparent;
  border: none;
  cursor: pointer;
}
image-zoom dialog::backdrop {
  background: rgba(0, 0, 0, 0.5);
}
image-zoom img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
checkbox-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  border: 1px solid #dfdfe1;
  border-radius: 4px;
  background: #ffffff;
}
checkbox-input label {
  flex: 1px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  padding: 12px;
}
checkbox-input[data-disabled] :is(checkbox-input label) {
  color: #a9a9af;
}
checkbox-input input[type=checkbox] {
  margin-right: 12px;
  cursor: pointer;
  background-color: #ffffff;
  -webkit-appearance: none;
  appearance: none;
  font: inherit;
  border: 1px solid #cfcfd1;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  display: grid;
  place-content: center;
}
checkbox-input input[type=checkbox]:checked {
  background-color: #005a54;
  border-color: #005a54;
}
checkbox-input input[type=checkbox]:disabled {
  background-color: #cfcfd1;
  border-color: #cfcfd1;
}
checkbox-input input[type=checkbox]::before {
  content: "";
  width: 16px;
  height: 16px;
  transform: scale(0);
  background: #ffffff;
  border-radius: 4px;
  transform-origin: bottom left;
  clip-path: polygon(88.637% 20.428%, 88.637% 20.428%, 89.323% 21.179%, 89.864% 22.008%, 90.262% 22.897%, 90.514% 23.827%, 90.621% 24.781%, 90.582% 25.739%, 90.395% 26.685%, 90.061% 27.599%, 89.578% 28.464%, 88.947% 29.262%, 42.765% 79.261%, 42.765% 79.261%, 42.393% 79.629%, 41.994% 79.961%, 41.571% 80.258%, 41.127% 80.519%, 40.663% 80.741%, 40.183% 80.925%, 39.688% 81.069%, 39.182% 81.171%, 38.666% 81.232%, 38.144% 81.25%, 38.144% 81.25%, 37.621% 81.224%, 37.106% 81.155%, 36.602% 81.044%, 36.109% 80.892%, 35.632% 80.701%, 35.172% 80.471%, 34.732% 80.204%, 34.314% 79.9%, 33.92% 79.561%, 33.554% 79.188%, 10.986% 54.188%, 10.986% 54.188%, 10.367% 53.381%, 9.899% 52.508%, 9.579% 51.589%, 9.407% 50.64%, 9.383% 49.681%, 9.505% 48.729%, 9.772% 47.803%, 10.184% 46.921%, 10.739% 46.101%, 11.437% 45.361%, 11.437% 45.361%, 12.244% 44.742%, 13.117% 44.273%, 14.037% 43.954%, 14.985% 43.782%, 15.944% 43.758%, 16.896% 43.88%, 17.822% 44.147%, 18.704% 44.559%, 19.524% 45.114%, 20.264% 45.812%, 38.267% 65.754%, 79.803% 20.739%, 79.803% 20.739%, 80.554% 20.053%, 81.383% 19.511%, 82.272% 19.113%, 83.202% 18.861%, 84.156% 18.754%, 85.114% 18.793%, 86.06% 18.98%, 86.975% 19.314%, 87.84% 19.797%, 88.637% 20.428%);
}
checkbox-input input[type=checkbox]:checked::before {
  transform: scale(1);
}
.checkbox-input--right {
  padding-right: 16px;
}
.garden-share-page--public {
  margin: 16px 0 32px;
  background: #ffffff;
  padding: 16px 16px 14px;
  box-shadow: 0px 1px 2px 0px #0000000a;
  outline: 0;
  border-radius: 4px;
  border: 1px solid #f2eae9;
}
.garden-share-page--public p {
  padding: 0;
  margin: 0 0 16px;
}
.garden-share-page--public p button {
  display: inline;
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  text-decoration: underline;
  cursor: pointer;
}
.garden-share-page--public a {
  text-decoration: underline;
}
.garden-share-page--public pre {
  border-radius: 4px;
  padding: 8px;
  background: #f2eae9;
  overflow: scroll;
}
.garden-share-page--rows {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin: 16px 0 32px;
}
.garden-share-page--row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 12px;
  background: #ffffff;
  padding: 16px 16px 14px;
  box-shadow: 0px 1px 2px 0px #0000000a;
  outline: 0;
  border-radius: 4px;
  border: 1px solid #f2eae9;
  height: 60px;
}
.garden-share-page--row > div {
  width: fit-content;
}
.garden-share-page--row .select-input {
  width: auto;
}
.garden-share-page--row .select-input--container {
  height: 32px;
  padding: 0;
  border: 0;
  background: transparent;
  box-shadow: none;
}
.garden-share-page--row .select-input--container svg {
  right: 0;
}
.garden-share-page--pending div:first-child {
  flex: 1px;
}
.garden-share-page--pending button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 0;
  background: transparent;
}
.garden-invite-page form {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
garden-hide-zone[data-hidden=true] {
  display: none;
}
text-complete {
  position: relative;
}
.text-complete {
  width: 375px;
  max-width: 100%;
}
.text-complete[data-fill=true] {
  width: 100%;
}
@media (max-width: 768px) {
  .text-complete[data-fill=mobile] {
    width: 100%;
  }
}
.text-complete--container {
  position: relative;
  height: 60px;
}
.text-complete--label {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 16px 14px 16px;
  color: #7a7986;
  pointer-events: none;
  transition: all 0.15s;
}
.text-complete:focus-within .text-complete--label,
.text-complete[data-filled=true] .text-complete--label {
  font-size: 0.8125rem;
  text-decoration: none;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 300;
  letter-spacing: 0.008125rem;
  line-height: 1.25rem;
  text-transform: none;
  padding-top: 20px;
  height: 8px;
}
.text-complete[data-disabled=true] .text-complete--label {
  color: #cfcfd1;
}
.text-complete[data-error=true] .text-complete--label {
  color: #c91737;
}
.text-complete--input {
  display: block;
  width: 100%;
  height: 100%;
  resize: none;
  box-shadow: 0px 1px 2px 0px #0000000a;
  outline: 0;
  border-radius: 4px;
  border: 1px solid #f2eae9;
  padding: 26px 16px 0;
  margin: 0;
  color: #131215;
  background: #ffffff;
}
.text-complete--input::placeholder {
  color: #bbbbbf;
}
.text-complete--input:not(:focus)::placeholder {
  color: transparent;
}
.text-complete--input:focus {
  border: 2px solid #edd0cc;
  padding-top: 25px;
  padding-left: 15px;
}
.text-complete[data-disabled=true][data-filled=true] .text-complete--input {
  color: #cfcfd1;
  -webkit-text-fill-color: #cfcfd1;
}
.text-complete[data-error=true] .text-complete--input {
  border: 1px solid #c91737;
  color: #c91737;
}
.text-complete[data-error=true] .text-complete--input:focus-visible {
  border: 2px solid #c91737;
}
.text-complete--menu {
  position: absolute;
  z-index: 1000;
  width: 100%;
  display: flex;
  pointer-events: auto;
  opacity: 1;
  overflow: scroll;
  flex-direction: column;
  row-gap: 8px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #f2eae9;
  box-shadow: 0px 4px 16px 1px #00000014;
  outline: 0;
  transition: opacity 0.2s;
}
.text-complete--menu:empty {
  pointer-events: none;
  opacity: 0;
}
.text-complete--menu[data-position=below] {
  top: calc(100% + 8px);
}
.text-complete--menu[data-position=above] {
  bottom: calc(100% + 8px);
}
.text-complete--menu-item {
  pointer-events: auto;
  cursor: pointer;
  padding: 16px 16px;
  height: 56px;
  margin-top: -8px;
  background: transparent;
}
.text-complete--menu-item:first-child {
  margin-top: 0;
}
.text-complete--menu-item:hover,
.text-complete--menu-item[data-selected=true] {
  background: #f2eae9;
}
about-plant-page h2 {
  margin-top: 32px;
  color: #131215;
}
about-plant-page dl {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  row-gap: 12px;
}
about-plant-page dt {
  flex-basis: 35%;
  overflow: hidden;
  white-space: nowrap;
}
about-plant-page dd {
  margin-left: 16px;
  flex-basis: calc(65% - 16px);
  color: #004742;
}
about-plant-page ul {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
about-plant-page ul a {
  color: #005a54;
}
.about-plant-page--no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  border: 1px dashed #edd0cc;
  border-radius: 4px;
  height: 400px;
}
clipboard-input {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  background: #f2eae9;
  border-radius: 4px;
  padding: 0 8px;
}
clipboard-input input {
  flex: 1px;
  border: 0;
  height: 38px;
  border-radius: 4px;
  background: transparent;
  font-size: 1rem;
  text-decoration: none;
  font-family: Chiswick Sans Text, sans-serif;
  font-weight: 300;
  letter-spacing: 0rem;
  line-height: 1.5rem;
  text-transform: none;
}
clipboard-input button {
  cursor: pointer;
  background: transparent;
  border: 0;
  padding: 4px;
  border-radius: 4px;
  margin: 0 0 0 16px;
  color: #004742;
}
clipboard-input button:hover {
  background: #fff5f5;
}
clipboard-input button svg:first-child {
  display: block;
}
clipboard-input[data-copied] :is(clipboard-input button svg:first-child) {
  display: none;
}
clipboard-input button svg:last-child {
  display: none;
  color: #005a54;
}
clipboard-input[data-copied] :is(clipboard-input button svg:last-child) {
  display: block;
}
.clipboard-input--tooltip {
  opacity: 0;
  position: absolute;
  background: #383945;
  border-radius: 4px;
  color: #ffffff;
  padding: 2px 4px;
  right: 0;
  top: 110%;
  pointer-events: none;
}
clipboard-input[data-copied] .clipboard-input--tooltip {
  transition: all 0.4s;
  opacity: 1;
  top: 105%;
}
.pricing-page marketing-header:not([data-scrolled]) .marketing-header--basket-items {
  border: 2px solid #f8c7bc;
}
.pricing-page--content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #FCF8F7;
  margin-top: -72px;
}
.pricing-page--hero {
  padding: 136px 64px 128px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #131215;
  background: #FFCFC3;
}
@media (max-width: 768px) {
  .pricing-page--hero {
    padding-bottom: 32px;
  }
}
.pricing-page--hero h1 {
  text-align: center;
}
.pricing-page--hero p {
  text-align: center;
}
.pricing-page--hero a {
  margin-top: 24px;
}
.pricing-page--plans {
  display: flex;
  flex-direction: column;
  margin: -82px 0 96px;
  max-width: 800px;
  width: calc(100% - 176px);
  background: #ffffff;
  padding: 32px;
  border-radius: 16px;
  border: 1px solid #f2eae9;
}
@media (max-width: 768px) {
  .pricing-page--plans {
    width: 100%;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: 32px 20px;
    background: transparent;
    row-gap: 16px;
  }
}
.pricing-page--plans table {
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 24px 0;
}
.pricing-page--plans thead th:first-child {
  width: auto;
}
.pricing-page--plans thead th {
  text-align: center;
  white-space: nowrap;
  width: 16%;
  padding: 8px 16px;
  border-right: 1px solid #f2eae9;
}
.pricing-page--plans thead th:last-child {
  border: 0;
}
.pricing-page--plans tbody tr:nth-child(even) {
  background: #f4f4f5;
}
.pricing-page--plans tbody tr th {
  text-align: left;
  padding: 16px;
  border-right: 1px solid #f2eae9;
}
.pricing-page--plans tbody tr td {
  text-align: center;
  vertical-align: middle;
  border-right: 1px solid #f2eae9;
}
.pricing-page--plans tbody tr td:last-child {
  border: 0;
}
.pricing-page--plans tbody tr td svg {
  vertical-align: middle;
  color: #005a54;
  width: 24px;
  height: 24px;
}
.pricing-page--plan {
  background: #ffffff;
  border: 1px solid #f2eae9;
  border-radius: 16px;
  padding: 24px 16px 16px;
}
.pricing-page--plan header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.pricing-page--plan p {
  margin: 0;
}
.pricing-page--plan-row {
  display: flex;
  column-gap: 8px;
  margin-bottom: 8px;
}
.pricing-page--plan-row svg {
  flex: 0 0 24px;
  height: 24px;
  width: 24px;
}
.pricing-page--faq {
  max-width: 800px;
  width: 100%;
  margin-bottom: 64px;
  padding: 0 88px;
}
@media (max-width: 768px) {
  .pricing-page--faq {
    padding: 0 20px;
  }
}
.pricing-page--faq h2 {
  text-align: center;
  padding: 0 0 32px 0;
}
.pricing-page--faq a {
  text-decoration: underline;
}
.db-page marketing-header:not([data-scrolled]) .marketing-header--basket-items {
  border: 2px solid #f8c7bc;
}
.db-page--content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #FCF8F7;
  margin-top: -72px;
}
.db-page--hero {
  padding: 136px 64px 128px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #131215;
  background: #FFCFC3;
}
@media (max-width: 768px) {
  .db-page--hero {
    padding: 96px 20px 32px;
    max-width: 100%;
  }
}
.db-page--hero h1 {
  text-align: center;
}
.db-page--hero p {
  text-align: center;
}
.db-page--hero a {
  margin-top: 24px;
}
@media (max-width: 768px) {
  .db-page--hero better-form {
    max-width: 100%;
  }
}
.db-page--hero form {
  width: 450px;
  max-width: 100%;
  padding-top: 32px;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
}
.db-page--hero form text-input {
  flex-grow: 1;
}
.db-page--hero form text-input input {
  border-radius: 999px;
}
.db-page--search-hero {
  padding: 96px 64px 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #131215;
  background: #FFCFC3;
}
@media (max-width: 768px) {
  .db-page--search-hero {
    padding-bottom: 32px;
  }
}
.db-page--search-hero form {
  width: 750px;
  padding-top: 32px;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
}
.db-page--search-hero form text-input {
  flex-grow: 1;
}
.db-page--search-hero form text-input input {
  border-radius: 999px;
}
.db-page--results {
  padding: 12px;
  margin: 16px 0;
  width: 750px;
  max-width: 100%;
}
.db-page--results > h2 {
  text-align: center;
  margin: 32px 0;
}
.db-page--result {
  display: block;
  width: 100%;
  padding: 20px;
  border-radius: 4px;
}
.db-page--result:last-child {
  border: 0;
}
.db-page--result:hover {
  background: #ffe9e5;
  border: 0;
}
.db-page--result p {
  margin: 8px 0 0;
  width: 80%;
}
.db-taxon-page {
  width: 100%;
  padding: 0 88px;
  max-width: 1080px;
  margin: 32px 0;
}
@media (max-width: 768px) {
  .db-taxon-page {
    padding: 0 20px;
    max-width: 100%;
  }
}
.db-taxon-page h2 {
  margin: 32px 0;
}
.db-taxon-page h3 {
  margin: 32px 0 12px;
}
.db-taxon-page form {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.db-taxon-page pre {
  width: 100%;
  overflow-y: scroll;
  background: #f2eae9;
  padding: 16px;
  border-radius: 8px;
}
.db-taxon-page dl {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  row-gap: 12px;
}
.db-taxon-page dt {
  flex-basis: 33%;
  flex-grow: 1;
  flex-shrink: 0;
  overflow: hidden;
  white-space: nowrap;
}
.db-taxon-page dd {
  margin-left: 16px;
  flex-basis: calc(67% - 16px);
  color: #004742;
}
.db-taxon-page--header-rank {
  display: flex;
  column-gap: 8px;
}
.db-taxon-page--header h2 {
  margin: 8px 0 16px;
}
.db-taxon-page--taxonomy a {
  text-decoration: underline;
}
.db-taxon-page--taxonomy p a {
  margin-right: 8px;
}
.db-taxon-page--references a {
  text-decoration: underline;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.db-taxons-page--reference {
  margin: 16px 0;
}
